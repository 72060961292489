import { ENVIRONMENT_KEYS } from './environment';
import {
  APP_INIT,
  AUTH_FINISH_ONBOARDING,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  FIRST_TIME,
} from '../store/actions';

export const EVENTS = {
  APPLICATION: {
    FIRST_INIT: '[Application] First Init',
    OPEN: '[Application] Open',
    PAGE_VIEW: 'Page View',
  },
  UPDATE_MANAGER: {
    DISMISS_UPDATE: '[Update Manager] Dismiss',
    UPDATE: '[Update Manager] Update',
  },
  KNOWLEDGE_ITEMS: {
    OPEN: '[Knowledge] Open',
    CLOSE: '[Knowledge] Close',
    OPEN_INFORMATION: '[Knowledge] Information - open',
    CLOSE_INFORMATION: '[Knowledge] Information - close',
    FILTER_BY_ALL: '[Knowledge] Filter by all',
    FILTER_BY_DOCUMENTS: '[Knowledge] Filter by documents',
    FILTER_BY_MEDIA: '[Knowledge] Filter by media',
    SEARCH_CLICK: '[Knowledge] Search - click',
    SEARCH_ON_BLUR: '[Knowledge] Search - on blur',
    EDIT_ITEM: '[Knowledge] Edit item',
    CLOSE_EDIT_MODE: '[Knowledge] Close edit mode',
    CREATE_KNOWLEDGE_ITEM: '[Knowledge] Create knowledge',
    UPDATE_KNOWLEDGE_ITEM: '[Knowledge] Update knowledge',
    TOC_SCROLL: '[Knowledge] TOC scroll',
    TOGGLE_SMART_FORMAT: '[Knowledge] Toggle smart format',
    CREATE_NEW_ITEM_BUTTON: '[Knowledge] Create new item button',
    UPLOAD_FILE_IMPRESSION: '[Knowledge] Upload file impression',
    UPLOAD_FILE_START: '[Knowledge] Upload file start',
    UPLOAD_FILE_DONE: '[Knowledge] Upload file done',
    OPEN_TOC: '[Knowledge] Open TOC',
    CLOSE_TOC: '[Knowledge] Close TOC',
    DOWNLOAD_FILE: '[Knowledge] Download file',
    DOWNLOAD_FILE_IMPRESSION: '[Knowledge] Download file impression',
    OPEN_IN_NEW_TAB: '[knowledge] Open in new tab',
  },
  SMART_FORMAT_CONVERSION: {
    TOGGLE_STATE: '[SmartFormat] Toggle state',
    START: '[SmartFormat] Process start',
    DONE: '[SmartFormat] Process done',
    FAILED: '[SmartFormat] Process failed',
  },
  ONBOARDING: {
    FINISH: '[Onboarding] finish',
    CLICK_ON_NEXT: '[Onboarding] next',
  },
  DRAWER: {
    OPEN: '[Drawer] Open',
    OPEN_FROM_ICON: '[Drawer] Click on icon',
    ABOUT_ORGANIZATION: '[Drawer] About Organization',
    ABOUT_US: '[Drawer] About C8.health',
    GIVE_FEEDBACK: '[Drawer] Give feedback',
    CONTACT: '[Drawer] Contact C8.health',
    SWITCH_ORGANIZATION: '[Drawer] switch organization',
  },
  AUTH: {
    LOGIN: '[Auth] Login',
    LOGOUT: '[Auth] Logout',
  },
  WELCOME: {},
  APPLICATION_SCREEN: {
    HEADER_CLICK: '[Header] Click on header',
  },
  NOTIFICATION_MODAL: {
    CONFIRM: '[Notification] Confirm',
    CONFIRM_AND_APPROVED: '[Notification] Confirm and Approved',
    CONFIRM_AND_DENIED: '[Notification] Confirm and Denied',
    LATER: '[Notification] Later',
  },
  NOTIFICATIONS: {
    OPEN_NOTIFICATION: '[Notification] Open from notification',
    OPEN_KNOWLEDGE_ITEM_FROM_NOTIFICATION: '[Notification] Open knowledge item',
    CLOSE_KNOWLEDGE_ITEM_FROM_NOTIFICATION: '[Notification] Close knowledge item',
  },
  LAB_ITEMS: {
    OPEN: '[Lab] Open',
    OPEN_INFORMATION: '[Lab] Information - open',
    CLOSE_INFORMATION: '[Lab] Information - close',
  },
  CHECKLISTS: {
    OPEN: '[Checklist] Open',
  },

  SEARCH: {
    SEARCHING: '[Search] Searching',
    NOT_FOUND: '[Search] Not found',
  },

  LANGUAGE_SELECTOR: {
    OPEN: '[Lang Selector] Open',
    SELECT_LANG: '[Lang Selector] Select lang',
  },
  MARKDOWN_EDITOR: {
    OPEN: '[Editor] Open',
  },
  USER_PROFILE: {
    OPEN: '[Profile] Open',
    COMPLETE: '[Profile] Complete profile',
    UPDATE: '[Profile] Update',
    SNOOZE: '[Profile] Snooze',
  },
};

export const EVENTS_BY_ACTIONS_MAP = {
  [APP_INIT]: EVENTS.APPLICATION.OPEN,
  [AUTH_FINISH_ONBOARDING]: EVENTS.ONBOARDING.FINISH,
  [AUTH_LOGOUT]: EVENTS.AUTH.LOGOUT,
  [AUTH_LOGIN]: EVENTS.AUTH.LOGIN,
  [FIRST_TIME]: EVENTS.APPLICATION.FIRST_INIT,
};

export const MIXPANEL_TOKENS = {
  [ENVIRONMENT_KEYS.PRODUCTION]: 'a03aba374445d95b9fa4ec14bd701629',
  [ENVIRONMENT_KEYS.STAGING]: '49966fd547743a765f002cdfc9b4df8e',
  [ENVIRONMENT_KEYS.DEVELOPMENT]: '49966fd547743a765f002cdfc9b4df8e',
};
