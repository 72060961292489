import { KNOWLEDGE_HIDE_EXPIRED_CONTENT_AFTER_IN_DAYS } from '../constants';
import { DateTime } from 'luxon';
import _ from 'lodash';

const expirationDate = DateTime.now()
  .plus({
    days: -KNOWLEDGE_HIDE_EXPIRED_CONTENT_AFTER_IN_DAYS,
  })
  .endOf('day');

export const filterByExpiryDate = ({ expiresAt }) => {
  if (_.isNull(expiresAt)) {
    return true;
  }

  return expiresAt && expirationDate.diff(DateTime.fromISO(expiresAt).endOf('day')) <= 0;
};

/**
 * Check if expiry date is arrived
 * True - show files
 * False - hide files
 *
 * @param {Boolean} { adminMode }
 */
export const useExpiryFilterWithAdmin = ({ adminMode }) => {
  if (adminMode) {
    return () => true;
  }
  return filterByExpiryDate;
};
