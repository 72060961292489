import React, { useCallback } from 'react';
import { KNOWLEDGE_TYPES } from 'shared/constants/knowledge.constants';
import {
  ArrowIcon,
  DocumentIcon,
  ImageIcon,
  ItemBinderIcon,
  NewTabIcon,
  PresentationIcon,
  VideoIcon,
  WebsiteLinkIcon,
} from '../../images';
import { Tags } from '../tags';
import sharedStyles from './items.module.scss';
import classnames from 'classnames';
import analyticsService from '../../helpers/analytics.service';
import { EVENTS } from 'shared/constants/analytics';
import classNames from 'classnames';

const TYPE_TO_ICON_MAPPER = {
  content: DocumentIcon,
  pdf: DocumentIcon,
  document: DocumentIcon,
  image: ImageIcon,
  video: VideoIcon,
  ppt: PresentationIcon,
  'external-link': WebsiteLinkIcon,
};

const KnowledgeItemComponent = React.memo(function KnowledgeItemComponent({
  item = {},
  showTags,
  knowledgeShowItemContent,
  itemStyle = 'regular',
}) {
  const { id, title, subtitle, tags, type, link } = item;
  const isContent = type === KNOWLEDGE_TYPES.CONTENT;
  const Icon = TYPE_TO_ICON_MAPPER[type] || ItemBinderIcon;

  const onOpen = useCallback(() => {
    analyticsService.track(EVENTS.KNOWLEDGE_ITEMS.OPEN, {
      client: 'widget',
      id,
      title,
      type,
      ...tags,
    });
  }, [id, title, type, tags]);

  const openContent = useCallback(() => {
    knowledgeShowItemContent(id);
    onOpen();
  }, [knowledgeShowItemContent, id, onOpen]);

  const renderItem = () => (
    <div
      key={id}
      className={classnames([sharedStyles.item, sharedStyles.clickable, sharedStyles[itemStyle]])}
      onClick={isContent ? openContent : null}>
      <div className={classNames([sharedStyles.wrapper, showTags && sharedStyles.withTags])}>
        <img className={sharedStyles.icon} src={Icon} alt={'knowledge'} />
        <div>
          <h4>{title}</h4>
          {showTags && (
            <>
              <h5>{subtitle}</h5>
              <Tags tags={tags} />
            </>
          )}
        </div>
      </div>
      <img
        className={classNames([
          isContent ? sharedStyles.arrow : sharedStyles.newTabIcon,
          !showTags && sharedStyles.alignCenter,
        ])}
        src={isContent ? ArrowIcon : NewTabIcon}
        alt={title}
      />
    </div>
  );

  const renderNonContentItem = () => (
    <a href={link} target="_blank" rel="noreferrer" onClick={onOpen}>
      {renderItem()}
    </a>
  );

  const render = () => {
    if (!id) {
      return null;
    }
    return isContent ? renderItem() : renderNonContentItem();
  };

  return render();
});

export { KnowledgeItemComponent };
