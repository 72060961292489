import { GET, POST } from '../utils/Executer';
import API from './API';
import { OWNERS_SERVICE_URL as servicePath } from '../constants/APIconstants';

class OwnersServiceAPI {
  async get() {
    return await API.client.execute(GET, servicePath);
  }

  async createOwner({ owner }) {
    return await API.client.execute(POST, servicePath, owner);
  }

  async update({ id, ...owner }) {
    return await API.client.execute(POST, `${servicePath}${id}`, owner);
  }

  async delete({ id, ownerId }) {
    console.log(`API.client`, ownerId);
    return await API.client.execute(POST, `${servicePath}${id}/delete`, { ownerId });
  }
}

export default new OwnersServiceAPI();
