import React from 'react';
import { NotFound } from '../../not-found';
import styles from './search-results.module.scss';
import sharedStyles from '../../widget/widget.module.scss';
import { Lang } from 'shared/constants';
import { KnowledgeItem, CompendiumItemComponent, ChecklistItemComponent } from '../../items';

const SearchResultsComponent = React.memo(function SearchResultsComponent({
  items = {},
  searchTerm = null,
  lang = Lang.WIDGET,
}) {
  const filterNonCategory = (list = []) => list.filter(({ type }) => type !== 'category');

  const knowledge = items?.knowledge.slice(0, 50) ?? [];
  const lab = items?.lab.slice(0, 50) ?? [];
  const admission = filterNonCategory(items?.admission).slice(0, 50) ?? [];

  const resultsNumber = knowledge.length + lab.length + admission.length;

  return (
    <div className={sharedStyles.itemsWrapper}>
      {searchTerm && (
        <div className={styles.searchWrapper}>
          <h2 className={styles.searchTerm}>{`"${searchTerm}"`}</h2>
          <h4 className={styles.results}>
            {lang.SEARCH_RESULTS.replace('{number}', resultsNumber)}
          </h4>
        </div>
      )}

      {!resultsNumber && <NotFound searchTerm={searchTerm} />}

      {knowledge.map(item => (
        <KnowledgeItem key={item.id} {...item} />
      ))}
      {lab.map(item => (
        <CompendiumItemComponent key={item.id} {...item} />
      ))}
      {admission.map(item => (
        <ChecklistItemComponent key={item.id} {...item} />
      ))}
    </div>
  );
});

export { SearchResultsComponent };
