import { USERS_MGMT_INVITE_USERS } from '../actions';
import { fetchUsers } from '../actions/users-management.actions';

export const onInviteFriendsMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === USERS_MGMT_INVITE_USERS) {
      dispatch(fetchUsers());
    }
  };

export const usersManagementMiddleware = [onInviteFriendsMiddleware];
