import { DateTime } from 'luxon';

export const formatDate = d => DateTime.fromISO(d).toLocaleString(DateTime.DATE_MED);
export const formatDateTime = d => DateTime.fromISO(d).toLocaleString(DateTime.DATETIME_MED);

export const isToday = d => {
  const date = DateTime.fromISO(d);
  const today = DateTime.now();
  return date.hasSame(today, 'day') && date.hasSame(today, 'month') && date.hasSame(today, 'year');
};
