import { connect } from 'react-redux';
import { WidgetComponent } from './widget.component';
import { getRoot } from 'shared/store/selectors/knowledge.selector';
import { knowledgeShowItemContent } from '../../store/actions/knowledge-viewer.actions';

const mapStateToProps = ({ search, knowledge, organization, lang, knowledgeViewer }) => {
  const knowledgeRoot = getRoot(knowledge);

  return {
    searchTerm: search.term,
    results: search.results,
    showKnowledgeBase: knowledgeRoot?.id,
    lang: lang.WIDGET,
    organization,
    knowledgeItemId: knowledgeViewer?.itemId,
  };
};

export const Widget = connect(mapStateToProps, { knowledgeShowItemContent })(WidgetComponent);
