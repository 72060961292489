import {
  KNOWLEDGE_DASHBOARD_UPDATE_CONTENT,
  KNOWLEDGE_UPDATE_CONTENT,
  KNOWLEDGE_USE_CACHE,
} from '.';
import knowledgeServiceApi from '../../api/knowledge.service.api';
import { KNOWLEDGE_PAGE_PARAMS, REVISION_LOG_ADVISORS } from '../../constants/knowledge.constants';
import { isConnected } from '../selectors/network.selector';

export const getKnowledgeContent = () => async (dispatch, getState) => {
  let state = getState();

  if (!isConnected(state)) {
    return dispatch({
      type: KNOWLEDGE_USE_CACHE,
    });
  }

  const pageSize = KNOWLEDGE_PAGE_PARAMS.pageSize;

  let [itemsRes, categoriesRes] = await Promise.all([
    knowledgeServiceApi.getItemsByPage({ pageSize, page: 1 }),
    knowledgeServiceApi.getCategories(),
  ]);

  if (itemsRes.error || categoriesRes.error) {
    return dispatch({
      type: KNOWLEDGE_USE_CACHE,
    });
  }

  let { total, items: firstPageItems } = itemsRes.data;
  let items = firstPageItems;

  if (total > firstPageItems.length) {
    const numberOfRequests = Math.ceil(total / pageSize);

    const pages = await Promise.all(
      Array.from({ length: numberOfRequests }).map((el, index) => {
        return knowledgeServiceApi.getItemsByPage({ pageSize, page: index + 1 });
      }),
    );

    const errors = pages.filter(page => page.error);
    items = pages.flatMap(page => page?.data?.items);

    if (errors.length > 0) {
      return dispatch({
        type: KNOWLEDGE_USE_CACHE,
      });
    }
  }

  let categoriesById = {};

  categoriesRes.data.forEach(category => (categoriesById[category.id] = category));

  let itemsById = {};

  items = items.map(item => ({
    ...item,
    revisionLog: item?.revisionLog
      ?.map(entry => {
        if (REVISION_LOG_ADVISORS.emailDomain.test(entry.email)) {
          return { ...entry, email: REVISION_LOG_ADVISORS.name };
        }
        return entry;
      })
      .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)),
  }));

  items.forEach(item => (itemsById[item.id] = item));

  dispatch({
    type: KNOWLEDGE_UPDATE_CONTENT,
    payload: {
      items,
      itemsById,
      categories: categoriesRes.data,
      categoriesById,
    },
  });
};

export const getKnowledgeDashboardContent = () => async (dispatch, getState) => {
  let state = getState();

  if (!isConnected(state)) {
    return dispatch({
      type: KNOWLEDGE_USE_CACHE,
    });
  }

  let [
    recentlyCreatedItemsRes,
    recentlyUpdatedItemsRes,
    itemsOpenEventsForOrganizationRes,
    itemsOpenEventsForUserRes,
  ] = await Promise.all([
    knowledgeServiceApi.getRecentlyCreatedItems(),
    knowledgeServiceApi.getRecentlyUpdatedItems(),
    knowledgeServiceApi.getKnowledgeItemsOpenEventsForOrganization(),
    knowledgeServiceApi.getKnowledgeItemsOpenEventsForUser(),
  ]);

  if (
    recentlyCreatedItemsRes.error ||
    recentlyUpdatedItemsRes.error ||
    itemsOpenEventsForOrganizationRes.error ||
    itemsOpenEventsForUserRes.error
  ) {
    return dispatch({
      type: KNOWLEDGE_USE_CACHE,
    });
  }

  dispatch({
    type: KNOWLEDGE_DASHBOARD_UPDATE_CONTENT,
    payload: {
      recentlyCreatedItems: recentlyCreatedItemsRes.data.items,
      recentlyUpdatedItems: recentlyUpdatedItemsRes.data.items,
      itemsOpenEventsForOrganization: itemsOpenEventsForOrganizationRes.data.items,
      itemsOpenEventsForUser: itemsOpenEventsForUserRes.data.items,
    },
  });
};

export const getFrequentlyUsedKnowledgeContent = () => async (dispatch, getState) => {
  const state = getState();

  if (!isConnected(state)) {
    return dispatch({
      type: KNOWLEDGE_USE_CACHE,
    });
  }

  const itemsOpenEventsForOrganizationRes =
    await knowledgeServiceApi.getKnowledgeItemsOpenEventsForOrganization();

  if (itemsOpenEventsForOrganizationRes.error) {
    return dispatch({
      type: KNOWLEDGE_USE_CACHE,
    });
  }

  dispatch({
    type: KNOWLEDGE_DASHBOARD_UPDATE_CONTENT,
    payload: {
      itemsOpenEventsForOrganization: itemsOpenEventsForOrganizationRes.data.items,
    },
  });
};
