import { USERS_MGMT_FETCH, USERS_MGMT_UPDATE_PERMISSIONS, USERS_MGMT_INVITE_USERS } from '.';
import userServiceApi from '../../api/user.service.api';

export const fetchUsers = () => async dispatch => {
  let { data, error } = await userServiceApi.getUsers();

  if (error) {
    console.log('Fail to update user permissions');
    return { error: error.message };
  }

  let { users } = data;

  dispatch({
    type: USERS_MGMT_FETCH,
    payload: { users },
  });
};

export const setPermission =
  ({ id, permissions }) =>
  async dispatch => {
    let { error } = await userServiceApi.setUserPermission({ id, permissions });

    if (error) {
      console.log('Fail to update user permissions');
      return { error: error.message };
    }

    dispatch({
      type: USERS_MGMT_UPDATE_PERMISSIONS,
      payload: { id, permissions },
    });
  };

export const inviteUsers =
  ({ emails }) =>
  async dispatch => {
    let { error, data } = await userServiceApi.inviteUsers({ emails });

    if (error) {
      console.log('Fail to invite users');
      return { error: data.message };
    }

    dispatch({
      type: USERS_MGMT_INVITE_USERS,
      payload: data,
    });
  };
