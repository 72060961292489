import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import {
  APPLICATION_WIPE_DATA,
  AUTH_LOGOUT,
  USERS_MGMT_FETCH,
  USERS_MGMT_UPDATE_PERMISSIONS,
} from '../actions';

const initialState = {
  list: null,
  lastUpdated: null,
};

const updateUsers = (state, { users }) =>
  update(state, {
    $merge: {
      list: users,
      lastUpdated: Date.now(),
    },
  });

const updatePermissions = (state, { id, permissions }) => {
  let index = state.list.findIndex(u => u.id === id);

  if (index === -1) return state;

  return update(state, {
    list: {
      [index]: { $merge: { permissions } },
    },
  });
};

const cleanData = () => {
  return initialState;
};

const actionsRepo = {
  [USERS_MGMT_FETCH]: updateUsers,
  [USERS_MGMT_UPDATE_PERMISSIONS]: updatePermissions,
  [APPLICATION_WIPE_DATA]: cleanData,
  [AUTH_LOGOUT]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
