import sanitizeHtml from 'sanitize-html';

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    // eslint-disable-next-line no-await-in-loop
    await callback(array[index], index, array);
  }
}

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

const toBase64 = text => Buffer.from(text).toString('base64');

const queryString = params =>
  Object.keys(params)
    .map(key => key + '=' + encodeURIComponent(params[key]))
    .join('&');

const convertToSec = (time = Date.now()) => (time / 1000) | 0;

const nowSEC = () => (Date.now() / 1000) | 0;

const getParentIdByPath = (path = '') => {
  if (!path) return null;

  return path.split(',').pop();
};

const camelCaseToWords = function (str) {
  str = str.replace(/_/, ' ').toLowerCase();
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const removeDiacritics = str => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

const normalizedContains = (haystack, needle) => {
  const regExp = new RegExp(needle, 'gi');
  return regExp.test(removeDiacritics(haystack));
};

const sortByAlphabet = (a, b) => {
  if (a < b) return -1;
  if (a > b) return 1;
  return 0;
};

const parseYoutubeUrl = url => {
  let parsedUrl = url;

  if (!url.includes('/embed/')) {
    parsedUrl = url.replace('/watch?v=', '/embed/');
  }

  if (url.includes('youtu.be')) {
    const path = url.split('/');
    const videoId = path[path.length - 1];
    return `https://youtube.com/embed/${videoId}`;
  }

  return parsedUrl;
};

// same sanitization rules as in backend
const sanitizeHtmlContent = html =>
  sanitizeHtml(html, {
    allowedTags: sanitizeHtml.defaults.allowedTags.concat(['img']),
    allowedAttributes: {
      '*': ['style'],
      a: ['href', 'name', 'target'],
      img: ['style', 'src', 'srcset', 'alt', 'title', 'width', 'height', 'loading'],
    },
    parseStyleAttributes: false,
  });

export {
  asyncForEach,
  timeout,
  toBase64,
  queryString,
  convertToSec,
  nowSEC,
  getParentIdByPath,
  camelCaseToWords,
  removeDiacritics,
  normalizedContains,
  sortByAlphabet,
  parseYoutubeUrl,
  sanitizeHtmlContent,
};
