import jwtDecode from 'jwt-decode';
import update from 'immutability-helper';
import {
  AUTH_ATTACH_USER_ID,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_UPDATE_USER,
  INITIAL_STATE,
  AUTH_UPDATE_TOKEN,
  AUTH_SET_REGION,
} from '../actions';
import { createReducer } from '../../utils/redux/reducers.utils';

const initialState = {
  token: null,
  tokenDetails: {},
  user: {},
  id: '',
  region: null,
};

const authLogin = (state, { token, user }) =>
  update(state, {
    $merge: {
      token,
      user: update(state.user, { $merge: user }),
      tokenDetails: { ...jwtDecode(token) },
    },
  });

const authUpdateToken = (state, { token }) =>
  update(state, {
    $merge: {
      token,
      tokenDetails: { ...jwtDecode(token) },
    },
  });

const attachUserId = (state, { id }) =>
  update(state, {
    id: { $set: id },
  });

const loadInitialState = (state, { auth = {} }) =>
  update(state, {
    $merge: auth,
  });

const updateUserDetails = (state, { user }) =>
  update(state, {
    $merge: {
      user: update(state.user, { $merge: user }),
    },
  });

const setRegion = (state, { region }) =>
  update(state, {
    region: { $set: region },
  });

const authLogout = () => {
  return initialState;
};

const actionsRepo = {
  [INITIAL_STATE]: loadInitialState,
  [AUTH_LOGIN]: authLogin,
  [AUTH_UPDATE_TOKEN]: authUpdateToken,
  [AUTH_ATTACH_USER_ID]: attachUserId,
  [AUTH_LOGOUT]: authLogout,
  [AUTH_UPDATE_USER]: updateUserDetails,
  [AUTH_SET_REGION]: setRegion,
};

export default createReducer({ initialState, actionsRepo });
