import {
  attachUserDetails,
  attachUserId,
  logout,
  setRegion,
  signIn,
} from '../store/actions/auth.actions';
import { ServiceBase } from './service.base';
import AuthenticationServiceApi from '../api/authentication.service.api';
import { ORGANIZATION_UNAUTHORIZED } from '../constants/applicationErrorCodes';
import { navigate } from './navigation.service';
import { ROUTES, DEVICE_FLAGS } from '../constants';
import { APPLICATION_STEPS } from '../constants/applicationSteps';
import { finishStep } from '../store/actions/application.actions';
import userServiceApi from '../api/user.service.api';
import API from '../api/API';

class UserService extends ServiceBase {
  constructor(props) {
    super(props);
    this.reducer = 'auth';
  }

  /**
   * Signin user
   * @param {*} { email }
   * @returns
   * @memberof UserService
   */
  async signin({ email }) {
    const { region } = await this._selectRegion({
      email,
    });

    if (!region) {
      navigate(ROUTES.NOT_ALLOWED, { email });
      return;
    }

    const { error, data } = await AuthenticationServiceApi.loginWithEmail({
      email,
    });

    if (error) {
      if (data.code === ORGANIZATION_UNAUTHORIZED) {
        console.info(data.message);
        navigate(ROUTES.NOT_ALLOWED, { email });
        return { error: '' };
      }
      return { error: data.message };
    }

    const { id } = data;

    // Attach user ID
    this.dispatch(setRegion({ region }));
    this.dispatch(attachUserDetails({ email }));
    this.dispatch(attachUserId({ id }));

    // User have created an OTP
    navigate(ROUTES.OTP_VERIFICATION, { email });

    return data;
  }

  /**
   * Contact us
   * @param {*} { email, fullName, phone }
   * @returns
   * @memberof UserService
   */
  async contactUs({ email, fullName, phone }) {
    let { error, data } = await AuthenticationServiceApi.contactUs({
      email,
      fullName,
      phone,
    });

    if (error) {
      return { error: data.message };
    }

    return data;
  }

  /**
   * Verify OTP code and sign if needed
   * @param {*} { code }
   * @returns
   * @memberof UserService
   */
  async verifyCode({ code }) {
    let { error, data } = await AuthenticationServiceApi.verifyCode({
      code,
      userId: this.state.id,
    });

    if (error) {
      console.log(`Fail to verify code [${code}] ${data.message}`);
      return { error: data.message, code: data.code };
    }

    console.log('Signin - Succeeded');
    // Data include token and user
    this.dispatch(signIn(data));

    return {};
  }

  /**
   *
   *
   * @returns
   * @memberof UserService
   */
  async init() {
    if (this.isTokenExpired) {
      console.log('User service - user has no valid token');

      // Force logout
      this._forceLogout();
      this._finishAuthStep();
      return;
    }

    let { error, data } = await userServiceApi.me();

    if (error?.response?.status === 401) {
      console.log('User service - user is not authenticated');

      // Force logout
      this._forceLogout();
      this._finishAuthStep();
      return;
    }

    console.log('User service - user is authenticated');

    const { user } = data;
    this.dispatch(attachUserDetails(user));

    let isOnboardingFinished = this.storeState.deviceFlags[DEVICE_FLAGS.ONBOARDING_FINISHED];

    if (!isOnboardingFinished) {
      navigate(ROUTES.ONBOARDING);
    }

    // Finish application step
    this._finishAuthStep();
  }

  /**
   * Select allowed region for authentication and further requests
   * @param {*} { email }
   * @private
   * @returns { region }
   * @memberof UserService
   */
  async _selectRegion({ email }) {
    const regionsAvailableResponse = await AuthenticationServiceApi.checkAvailabilityByRegion({
      email,
    });

    const index = regionsAvailableResponse.findIndex(({ data }) => data?.isAvailable);
    const allowedRegion = API.getAPIRegions()[index];

    API.setClientByRegion(allowedRegion);

    return { region: allowedRegion };
  }

  _finishAuthStep() {
    this.dispatch(finishStep({ step: APPLICATION_STEPS.AUTHENTICATION_PROCESS }));
  }

  _forceLogout() {
    this.dispatch(logout());
  }

  get userId() {
    return this.state.id || null;
  }

  get organizationId() {
    return this.state.user?.organizationId || null;
  }

  get region() {
    return this.state?.region || null;
  }

  // ========== Auth methods =============
  get accessToken() {
    let { token } = this.state;
    return token && token;
  }

  get isTokenExpired() {
    let { tokenDetails } = this.state;
    if (tokenDetails && tokenDetails.exp) {
      return 1000 * tokenDetails.exp - Date.now() < 5000;
    }
    return true;
  }

  get isAuthenticated() {
    return !this.isTokenExpired;
  }
}

export default new UserService();
