import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { AUTH_LOGOUT, ORGANIZATION_UPDATE } from '../actions';

const initialState = {
  logo: 'https://storage.googleapis.com/headtotoe-dev-app-assets/universite_de_geneve.png',
};

const updateOrganization = (state, organizationData) =>
  update(state, {
    $set: organizationData,
  });

const authLogout = () => {
  return initialState;
};

const actionsRepo = {
  [ORGANIZATION_UPDATE]: updateOrganization,
  [AUTH_LOGOUT]: authLogout,
};

export default createReducer({ initialState, actionsRepo });
