import userService from '../../services/user.service';
import {
  APPLICATION_REFRESH_DATE,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
  OWNERS_DELETE,
  OWNERS_UPDATE,
} from '../actions';
import { applicationRefreshDate } from '../actions/application.actions';
import { getOwners } from '../actions/owners.actions';
import { updateStateToStorage } from '../actions/storage.actions';

export const ownersFetchedMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === OWNERS_UPDATE) {
      dispatch(updateStateToStorage());
    }
  };

export const ownersInitMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(getOwners());
    }
  };

export const ownersDeleteMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === OWNERS_DELETE) {
      dispatch(applicationRefreshDate());
    }
  };

export const ownersMiddleware = [
  ownersFetchedMiddleware,
  ownersInitMiddleware,
  ownersDeleteMiddleware,
];
