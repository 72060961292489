import { currentEnvironment } from '../services/Environment';

export const REMOTE_CONFIG_KEYS = {
  APPLICATION_VERSIONS: 'APPLICATION_VERSIONS',
  LANGUAGE: 'LANGUAGE',
  MAINTENANCE: 'MAINTENANCE',
  GENERAL_CONFIG: 'GENERAL_CONFIG',
  FEATURE_FLAGS: 'FEATURE_FLAGS',
};

export const FEATURE_FLAGS = {
  KNOWLEDGE_STATISTICS: 'KNOWLEDGE_STATISTICS',
};

export const REMOTE_CONFIG_DEFAULT = {
  [REMOTE_CONFIG_KEYS.APPLICATION_VERSIONS]: {},
  [REMOTE_CONFIG_KEYS.LANGUAGE]: {}, // TODO: update to default props
  [REMOTE_CONFIG_KEYS.MAINTENANCE]: { ACTIVE: false, ESTIMATED_END: '' },
  [REMOTE_CONFIG_KEYS.GENERAL_CONFIG]: {
    FEEDBACK_FORM: '',
    ALLOW_IFRAME_DOMAINS: ['headtotoe.io', 'c8.health', 'c8health.com'],
    USER_PROFILE_MANDATORY_DATE: '2023-04-24',
  },
  [REMOTE_CONFIG_KEYS.FEATURE_FLAGS]: { [FEATURE_FLAGS.KNOWLEDGE_STATISTICS]: true },
};

const USE_LOCAL_CONFIG_BY_ENV = {
  development: false,
  staging: false,
  production: false,
};

export const USE_LOCAL_CONFIG = USE_LOCAL_CONFIG_BY_ENV[currentEnvironment()];
export const MINIMUM_FETCH_INTERVAL_MS = 30000;
