/**
 * Filter by tags
 * True - show files
 * False - hide files
 *
 * @param {Boolean} { filters }
 */
export const filterByTags =
  ({ filters }) =>
  ({ tags }) => {
    try {
      if (filters.SPECIALTY.size > 0 && !filters.SPECIALTY.has(tags.specialty)) {
        return false;
      }

      if (filters.SUBSPECIALTY.size > 0 && !filters.SUBSPECIALTY.has(tags.subspecialty)) {
        return false;
      }

      if (filters.CONTENT_TYPE.size > 0 && !filters.CONTENT_TYPE.has(tags.contentType)) {
        return false;
      }

      if (filters.TARGET_AUDIENCE.size > 0) {
        let res = tags.targetAudience.filter(key => filters.TARGET_AUDIENCE.has(key));

        if (res.length === 0) {
          return false;
        }
      }

      return true;
    } catch (e) {
      return false;
    }
  };
