import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { getDrugClassIcon } from '../../constants/drugClassIconsMapper';
import { ArrowIcon, CompendiumIcon } from '../../images';
import { Tags } from '../tags';
import sharedStyles from './items.module.scss';
import styles from './compendium-item.module.scss';
import classnames from 'classnames';
import { CompendiumPreview } from './compendium-preview/compendium-preview';
import analyticsService from '../../helpers/analytics.service';
import { EVENTS } from 'shared/constants/analytics';

const CompendiumItemComponent = React.memo(function CompendiumItemComponent({
  id,
  title,
  tags,
  value,
  drugClass,
  ...props
}) {
  const ref = useRef(null);
  const [valueHeight, setValueHeight] = useState(0);
  const isLargeValue = valueHeight > styles.maxHeight.replace('px', '');
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    setValueHeight(ref.current.clientHeight);
  }, [value, setValueHeight]);

  const onOpen = useCallback(() => {
    analyticsService.track(EVENTS.LAB_ITEMS.OPEN, {
      client: 'widget',
      id,
      title,
      isLargeValue,
      ...tags,
    });
  }, [id, title, isLargeValue, tags]);

  const openPreview = useCallback(() => {
    setShowPreview(true);
    onOpen();
  }, [setShowPreview, onOpen]);

  const closePreview = useCallback(() => {
    setShowPreview(false);
  }, [setShowPreview]);

  return (
    <>
      <div
        key={id}
        className={classnames([sharedStyles.item, isLargeValue && sharedStyles.clickable])}
        onClick={isLargeValue ? openPreview : null}>
        <div className={sharedStyles.wrapper}>
          <img className={sharedStyles.icon} src={CompendiumIcon} alt={'compendium'} />
          <div>
            <h4>{title}</h4>
            <div
              ref={ref}
              className={classnames([styles.value, isLargeValue && styles.largeValue])}>
              <ReactMarkdown>{value}</ReactMarkdown>
            </div>
            <Tags tags={tags} />
          </div>
        </div>
        <div className={styles.rightSideIcons}>
          {drugClass && (
            <img
              className={styles.drugClassIcon}
              src={getDrugClassIcon(drugClass)}
              alt={drugClass}
            />
          )}
          {isLargeValue && <img className={sharedStyles.arrow} src={ArrowIcon} alt={title} />}
        </div>
      </div>
      {showPreview && (
        <CompendiumPreview
          {...{ id, title, tags, value, drugClass, ...props }}
          close={closePreview}
        />
      )}
    </>
  );
});

export { CompendiumItemComponent };
