import { DELETE, GET, POST } from '../utils/Executer';
import API from './API';
import { FAVORITES_SERVICE_URL as servicePath } from '../constants/APIconstants';

class FavoritesServiceAPI {
  async get() {
    return await API.client.execute(GET, `${servicePath}`);
  }

  async create({ itemId }) {
    return await API.client.execute(POST, `${servicePath}`, { itemId });
  }

  async delete({ id }) {
    return await API.client.execute(DELETE, `${servicePath}${id}`);
  }
}

export default new FavoritesServiceAPI();
