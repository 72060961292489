import { DateTime } from 'luxon';
import { REGIONS } from '../../constants/region.constants';
import { isToday } from '../../utils/date.utils';

export const getUser = ({ auth }) => {
  let { user } = auth;

  return user;
};

export const getRegion = ({ auth }) => {
  return auth.region ?? REGIONS.US;
};

export const isProfileMandatory = ({ auth, generalConfig }) => {
  const { user } = auth;
  const mandatoryDate = DateTime.fromISO(generalConfig.USER_PROFILE_MANDATORY_DATE);
  const userCreatedAt = DateTime.fromISO(user.createdAt);

  return generalConfig.USER_PROFILE_MANDATORY_DATE && userCreatedAt >= mandatoryDate;
};

export const needToUpdateProfile = state => {
  const { user } = state.auth;

  return (
    (isProfileMandatory(state) && user?.profileCompletedAt === null) ||
    (user?.profileCompletedAt === null && !isToday(user?.snooze?.lastCheck))
  );
};

export const canSnoozeProfileUpdate = ({ auth }) => {
  const { user } = auth;
  return user?.snooze?.counter < 5;
};
