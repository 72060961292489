import API from '../../api/API';
import { DEVICE_FLAGS, ROUTES } from '../../constants';
import { APPLICATION_STEPS } from '../../constants/applicationSteps';
import { navigate } from '../../services/navigation.service';
import userService from '../../services/user.service';
import {
  APP_FINISH_STEP,
  AUTH_FINISH_ONBOARDING,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_UPDATE_TOKEN,
} from '../actions';
import { updateDeviceFlags } from '../actions/deviceFlags.actions';
import { updateStateToStorage } from '../actions/storage.actions';
import { attachUserId } from '../actions/auth.actions';

export const authInit =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (
      action.type === APP_FINISH_STEP &&
      action.payload?.step === APPLICATION_STEPS.PERSIST_STORAGE
    ) {
      if (!userService.isTokenExpired) {
        API.setClientByRegion(userService.region);
        if (userService.accessToken) {
          API.setToken(userService.accessToken);
        }
        dispatch(attachUserId({ id: userService.userId }));
      }

      userService.init();
    }
  };

export const authUpdateToken = () => next => action => {
  if (action.type === AUTH_UPDATE_TOKEN) {
    API.setClientByRegion(userService.region);
    API.setToken(action.payload.token);
  }

  next(action);
};

export const authLoginProcess =
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type === AUTH_LOGIN) {
      // Attach user ID
      dispatch(attachUserId({ id: userService.userId }));
      API.setToken(action.payload.token);
      next(action);
      dispatch(updateStateToStorage());
    } else {
      next(action);
    }
  };

export const authLogoutProcess =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action);

    if (action.type === AUTH_LOGOUT) {
      // Attach user ID
      dispatch(attachUserId({ id: '' }));
      API.setToken(null);
      dispatch(updateStateToStorage());
    }
  };

export const authOnboardingFinished =
  ({ dispatch, getState }) =>
  next =>
  action => {
    next(action);

    if (action.type === AUTH_FINISH_ONBOARDING) {
      dispatch(updateDeviceFlags({ key: DEVICE_FLAGS.ONBOARDING_FINISHED }));

      navigate(ROUTES.WELCOME);
    }
  };

export const authMiddleware = [
  authInit,
  authLoginProcess,
  authUpdateToken,
  authLogoutProcess,
  authOnboardingFinished,
];
