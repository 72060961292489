import { POST } from '../utils/Executer';
import API from './API';
import { AUTHENTICATION_SERVICE_URL as servicePath } from '../constants/APIconstants';

class AuthenticationServiceAPI {
  async loginWithEmail({ email }) {
    return await API.client.execute(POST, `${servicePath}`, { email });
  }

  async verifyCode(data) {
    return await API.client.execute(POST, `${servicePath}verify-code`, data);
  }

  async contactUs(data) {
    return await API.client.execute(POST, `${servicePath}contact-us`, data);
  }

  async checkAvailabilityByRegion({ email }) {
    return await Promise.all(
      Object.values(API.executers).map(
        async client => await client.execute(POST, `${servicePath}check`, { email }),
      ),
    );
  }
}

export default new AuthenticationServiceAPI();
