import _ from 'lodash';
import { DateTime } from 'luxon';
import { KNOWLEDGE_TO_BE_EXPIRED_ITEMS_IN_DAYS } from '../../constants';
import { getParentIdByPath } from '../../utils';

export const getByIdCategory = (category, idToSearch) => {
  if (category.children && category.children.length !== 0) {
    let result;
    category.children.forEach(item => {
      if (result) return false;
      let res = getByIdCategory(item, idToSearch);

      if (res) result = res;
    });

    return result;
  }

  if (category.knowledge_item_medias && category.knowledge_item_medias !== 0) {
    return category.knowledge_item_medias.find(({ id }) => idToSearch === id);
  }
};

export const getKnowledgeItemById = ({ knowledge }, id) => {
  return getItemById(knowledge, { id });
};

export const getRoot = ({ categories }) => {
  if (!categories) return {};
  return categories.find(category => category.path === 'root');
};

export const getCategoriesByPath = ({ categories, categoriesById }, path) => {
  if (_.isNull(categories)) return null;

  let parentId = getParentIdByPath(path);

  return getCategoriesById({ categoriesById }, parentId);
};

export const getCategoriesById = ({ categoriesById }, id) => {
  if (_.isNull(categoriesById)) return null;

  if (!categoriesById[id]) return null;

  return categoriesById[id]?.subcategories.map(i => getCategoryById({ categoriesById }, { id: i }));
};

export const getCategoryById = ({ categoriesById }, { id }) => {
  return !_.isNull(categoriesById) ? categoriesById[id] : {};
};

export const getItemById = ({ itemsById }, { id }) => {
  return itemsById?.[id] || null;
};

export const generateBreadcrumbs = ({ categoriesById }, { pathParts = [] }) => {
  return pathParts ? pathParts.map(id => categoriesById[id]) : [];
};

export const getItemsByCategoryId = ({ itemsById, categoriesById }, { id }) => {
  let { knowledgeItems = [] } = !_.isNull(categoriesById) ? categoriesById[id] : {};

  return knowledgeItems
    .map(({ id, linked, ownerId = null, ...itemData }) => {
      const item = itemsById[id] || null;

      if (!!item && linked) {
        return { ...item, linked, linkOwnerId: itemData.linkOwnerId ?? ownerId };
      }
      return item;
    })
    .filter(i => !!i);
};

export const getExpiredItems = ({ items }, auth, owners) => {
  const expirationDate = DateTime.now().plus({
    days: KNOWLEDGE_TO_BE_EXPIRED_ITEMS_IN_DAYS,
  });

  const matchingOwners = Object.values(owners.items).filter(owner => owner.email === auth.user.email);

  return items
    ?.filter(({ expiresAt, ownerId }) => {
      return (
        ownerId &&
        matchingOwners.findIndex(owner => owner.id === ownerId) !== -1 &&
        expiresAt &&
        expirationDate.diff(DateTime.fromISO(expiresAt)) > 0
      );
    })
    .sort((a, b) => DateTime.fromISO(b.expiresAt).diff(DateTime.fromISO(a.expiresAt)));
};

export const getRecentlyCreatedItems = ({ itemsById, recentlyCreatedItems }) => {
  return recentlyCreatedItems?.map(item => itemsById?.[item.id] || null).filter(i => !!i);
};

export const getRecentlyUpdatedItems = ({ itemsById, recentlyUpdatedItems }) => {
  return recentlyUpdatedItems?.map(item => itemsById?.[item.id] || null).filter(i => !!i);
};

export const getKnowledgeItemsOpenEventsForOrganization = ({ itemsById, itemsOpenEventsForOrganization }) => {
  return itemsOpenEventsForOrganization?.map(item => itemsById?.[item.id] || null).filter(i => !!i);
};

export const getKnowledgeItemsOpenEventsForUser = ({ itemsById, itemsOpenEventsForUser, id }) => {
  return itemsOpenEventsForUser?.map(item => itemsById?.[item.id] || null).filter(i => !!i);
};

export const getLocations = ({ categories, categoriesById }, { type = 'category', path }) => {
  let locations = [];

  // check if need to section or category
  if (path.split(',').length % 2 !== 0) {
    locations = categories.filter(
      i => i.type === type && i.pathParts?.length % 2 === 0 && !!i.path,
    );

    let root = getRoot({ categories });
    locations.unshift({ fullPath: root.id, title: root.title });
  } else {
    locations = categories.filter(i => i.type === type && i.pathParts?.length % 2 !== 0);
  }

  return locations.map(item => {
    let path = generateBreadcrumbs({ categoriesById }, { pathParts: item.pathParts }).map(
      t => t?.title,
    );

    path = [...path, item.title].reverse();

    // Remove knowledge base root
    if (path.length > 1) {
      path.pop();
    }

    return {
      id: item.fullPath,
      value: path.join(' | '),
    };
  });
};

export const getLocationsOfKnowledge = ({ categories, categoriesById }) => {
  let locations = categories.filter(i => i.type === 'knowledge');

  return locations.map(item => {
    let path = generateBreadcrumbs({ categoriesById }, { pathParts: item.pathParts }).map(
      t => t?.title,
    );

    path = [...path, item.title].reverse();

    // Remove knowledge base root
    if (path.length > 1) {
      path.pop();
    }

    return {
      id: item.id,
      value: path.join(' | '),
    };
  });
};

export const getKnowledgeParentsByItemId = ({ categories }, { id }) => {
  return categories.filter(
    cat => cat.type === 'knowledge' && !!cat.knowledgeItems.find(item => item.id === id),
  );
};
