import {
  AUTH_ATTACH_USER_ID,
  AUTH_FINISH_ONBOARDING,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_UPDATE_USER,
  AUTH_UPDATE_TOKEN,
  AUTH_SET_REGION,
  PROFILE_COMPLETED,
} from '.';
import userServiceApi from '../../api/user.service.api';
import { USER_PROFILE_SOURCE } from '../../constants';

export const signIn =
  ({ token, user }) =>
  dispatch => {
    dispatch({
      type: AUTH_LOGIN,
      payload: { token, user },
    });
  };

export const updateToken = ({ token }) => ({
  type: AUTH_UPDATE_TOKEN,
  payload: { token },
});

export const attachUserDetails = user => dispatch => {
  dispatch({
    type: AUTH_UPDATE_USER,
    payload: { user },
  });
};

export const setRegion =
  ({ region }) =>
  dispatch => {
    dispatch({
      type: AUTH_SET_REGION,
      payload: { region },
    });
  };

export const attachUserId = ({ id }) => ({
  type: AUTH_ATTACH_USER_ID,
  payload: { id },
});

export const logout = () => async dispatch => {
  dispatch({ type: AUTH_LOGOUT });
};

export const finishOnboarding = () => async dispatch => {
  dispatch({ type: AUTH_FINISH_ONBOARDING });
};

export const updateUserData =
  ({ id, user: userData, source = USER_PROFILE_SOURCE.PROFILE_PAGE }) =>
  async dispatch => {
    const res = await userServiceApi.updateUser({ id, user: userData });

    if (res.error) {
      return res;
    }

    dispatch({
      type: AUTH_UPDATE_USER,
      payload: {
        user: res?.data?.user,
      },
    });

    if (
      source === USER_PROFILE_SOURCE.POPUP &&
      !userData?.profileCompletedAt &&
      res?.data?.user?.profileCompletedAt
    ) {
      dispatch({
        type: PROFILE_COMPLETED,
      });
    }

    return res;
  };

export const snoozeProfile =
  ({ id }) =>
  async () => {
    const res = await userServiceApi.snoozeProfile({ id });

    if (res.error) {
      throw new Error(res.data.message);
    }
  };
