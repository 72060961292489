export const ROUTES = {
  ONBOARDING: 'Onboarding',
  WELCOME: '/signin',
  OTP_VERIFICATION: '/verification',
  NOT_ALLOWED: '/not-allowed',

  ROOT: '/',
  PDF_VIEWER: 'Pdf Viewer',
  NOTIFICATION_MODAL: 'Notification Modal',
  HOME: '/',
  CONTENT_ITEM: '/knowledge/:id',
  ITEMS: 'Knowledge items',

  CHECKLISTS: 'Checklists',
  LABS: '/compendium',
  LABS_CATEGORIES: '/compendium/:id',

  ADMISSION: '/admission',
  ADMISSION_CATEGORIES: '/admission/:id',

  FAVORITES: '/favorites',
  SEARCH: '/search',

  USER_PROFILE_POPUP: '/profile-popup',
  USER_PROFILE: '/profile',
  SWITCH_ORGANIZATION: '/organizations',
  OWNERS_MANAGEMENT: '/owners',
  USERS_MANAGEMENT: '/users',
  COMMUNICATION_CENTER: '/communication',
  KNOWLEDGE: '/knowledge',
  KNOWLEDGE_ITEMS: '/knowledge/items/:id',
};

export const INTERNAL_ROUTING = {
  QUERY_PARAMS: {
    KNOWLEDGE: 'category',
    CHECKLIST: 'checklist',
  },
};
