import { REGISTER_API_KEY } from '.';

export const registerApiKey =
  ({ apiKey }) =>
  dispatch => {
    dispatch({
      type: REGISTER_API_KEY,
      payload: { apiKey },
    });
  };
