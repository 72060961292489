import { getRemoteConfig, fetchAndActivate, getValue } from 'firebase/remote-config';
import {
  REMOTE_CONFIG_DEFAULT,
  USE_LOCAL_CONFIG,
  MINIMUM_FETCH_INTERVAL_MS,
} from 'shared/constants';
import { firebaseInit } from './firebase.helper';

class RemoteConfigService {
  async init() {
    // TODO: move to central place
    const app = firebaseInit();

    this.remoteConfig = getRemoteConfig(app);

    this.remoteConfig.settings.minimumFetchIntervalMillis = MINIMUM_FETCH_INTERVAL_MS;

    this.remoteConfig.defaultConfig = REMOTE_CONFIG_DEFAULT;

    await this.fetchRemoteConfig();
  }

  async fetchRemoteConfig() {
    const fetchedRemotely = await fetchAndActivate(this.remoteConfig);

    if (!fetchedRemotely) {
      console.log(
        'No configs were fetched from the backend, and the local configs were already activated',
        fetchedRemotely,
      );
    } else {
      console.debug('Remote config has fetched');
    }
  }

  getValue(key) {
    if (USE_LOCAL_CONFIG) {
      console.warn('[Remote Config] Using local config', { key });
      return REMOTE_CONFIG_DEFAULT[key];
    }

    return getValue(this.remoteConfig, key).asString();
  }

  getJsonValue(key) {
    if (USE_LOCAL_CONFIG) {
      console.warn('[Remote Config] Using local config', { key });
      return REMOTE_CONFIG_DEFAULT[key];
    }

    try {
      return JSON.parse(getValue(this.remoteConfig, key).asString());
    } catch (e) {
      console.error('[Remote Config] Fail to parse value', {
        message: e.message,
      });
      return REMOTE_CONFIG_DEFAULT[key] || {};
    }
  }
}

export default new RemoteConfigService();
