import React, { useCallback } from 'react';
import { ChecklistIcon, NewTabIcon } from '../../images';
import { Tags } from '../tags';
import sharedStyles from './items.module.scss';
import { appURL } from 'shared/constants/APIconstants';
import classnames from 'classnames';
import analyticsService from '../../helpers/analytics.service';
import { EVENTS } from 'shared/constants/analytics';

const ChecklistItemComponent = React.memo(function ChecklistItemComponent({ id, title, tags }) {
  const link = `${appURL}admission?checklist=${id}`;

  const onOpen = useCallback(() => {
    analyticsService.track(EVENTS.CHECKLISTS.OPEN, {
      client: 'widget',
      id,
      title,
      link,
      ...tags,
    });
  }, [id, title, tags, link]);

  return (
    <a href={link} target="_blank" rel="noreferrer" onClick={onOpen}>
      <div key={id} className={classnames([sharedStyles.item, sharedStyles.clickable])}>
        <div className={sharedStyles.wrapper}>
          <img className={sharedStyles.icon} src={ChecklistIcon} alt={'checklist'} />
          <div>
            <h4>{title}</h4>
            <Tags tags={tags} />
          </div>
        </div>
        <img className={sharedStyles.newTabIcon} src={NewTabIcon} alt={title} />
      </div>
    </a>
  );
});

export { ChecklistItemComponent };
