import {
  KNOWLEDGE_CREATE_ITEM,
  KNOWLEDGE_CREATE_LINK,
  KNOWLEDGE_DELETE_ITEM,
  KNOWLEDGE_DELETE_LINK,
  KNOWLEDGE_UPDATE_ITEM,
  KNOWLEDGE_UPDATE_LINK,
  KNOWLEDGE_UPDATE_ITEMS_ORDER,
  KNOWLEDGE_UPDATE_ITEM_LOCATION,
  KNOWLEDGE_MOVE_LINK,
  KNOWLEDGE_UPDATE_ITEM_CONTENT,
} from '.';
import knowledgeServiceApi from '../../api/knowledge.service.api';
import axios from 'axios';
import { navigate } from '../../services/navigation.service';
import { ROUTES } from '../../constants';
import { getAssetsServiceBaseURL } from '../../constants/APIconstants';
import { getRegion } from '../selectors/user.selector';

const flattenDataWithTags = (data, tags) => {
  const tagsToInclude = Object.keys(tags).reduce((obj, key) => {
    if (tags[key] === undefined || tags[key] === null || tags[key].length === 0) {
      return obj;
    }
    return { ...obj, [key]: tags[key] };
  }, {});

  return { ...data, ...tagsToInclude };
};

export const getUploadUrl =
  ({ type, size, name }) =>
  async () => {
    let res = await knowledgeServiceApi.getUploadUrl({ type, size, name });

    return res.data;
  };

export const fetchKnowledgeItemContent =
  ({ id }) =>
  async dispatch => {
    const res = await knowledgeServiceApi.getItemContent({ id });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_ITEM_CONTENT,
      payload: {
        item: res.data,
      },
    });

    return res.data;
  };

export const createKnowledgeItem =
  ({ tags, ...data }) =>
  async dispatch => {
    let form = new FormData();
    const flatData = flattenDataWithTags(data, tags);

    for (var key in flatData) {
      form.append(key, flatData[key]);
    }

    let res = await knowledgeServiceApi.createKnowledgeItem({ data: form });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_CREATE_ITEM,
      payload: {
        item: res.data,
        categoryId: flatData.categoryId,
      },
    });

    return res.data;
  };

export const updateKnowledgeItem =
  ({ id, tags, ...data }) =>
  async dispatch => {
    let form = new FormData();
    const flatData = flattenDataWithTags(data, tags);

    for (var key in flatData) {
      form.append(key, flatData[key]);
    }

    let res = await knowledgeServiceApi.updateKnowledgeItem({ id, data: form });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_ITEM,
      payload: {
        item: res.data,
      },
    });

    return res.data;
  };

export const updateKnowledgeItemsOrder =
  ({ id, itemId, index }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.updateKnowledgeItemsOrder({
      id,
      itemId,
      index,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_ITEMS_ORDER,
      payload: { id, itemId, index },
    });
  };

export const moveKnowledgeItem =
  ({ id, categoryId, sourceId }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.moveKnowledgeItem({
      id,
      categoryId,
      sourceId,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_ITEM_LOCATION,
      payload: { id, categoryId, sourceId },
    });
  };

export const deleteKnowledgeItem =
  ({ id }) =>
  async dispatch => {
    let res = await knowledgeServiceApi.deleteKnowledgeItem({ id });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_DELETE_ITEM,
      payload: {
        id,
      },
    });
  };

export const uploadDocument = (file, onUploadProgress) => async (dispatch, getState) => {
  const { type, size, name } = file;

  const {
    url,
    id: assetId,
    type: knowledgeType,
    fileUrl,
  } = await dispatch(getUploadUrl({ type, size, name }));

  if (!fileUrl) {
    throw new Error('Failed to get upload url');
  }

  await axios.put(url, file, {
    headers: {
      'Content-Type': type,
    },
    onUploadProgress,
  });

  const region = getRegion(getState());

  return {
    url: `${getAssetsServiceBaseURL(region)}${fileUrl}`,
    assetId,
    type: knowledgeType,
  };
};

export const uploadFile =
  ({ ocr, file, onUploadProgress }) =>
  async dispatch => {
    try {
      const { url, assetId, type } = await dispatch(uploadDocument(file, onUploadProgress));

      if (ocr) {
        await knowledgeServiceApi.triggerOcrConversion({ assetId });
      }

      return { url, assetId, type };
    } catch (error) {
      return { error: error.message };
    }
  };

export const triggerOcr =
  ({ assetId }) =>
  async () => {
    await knowledgeServiceApi.triggerOcrConversion({ assetId });
  };

export const fetchOcrData =
  ({ assetId }) =>
  async () => {
    const res = await knowledgeServiceApi.fetchOcrData({ assetId });

    if (res.error) {
      throw new Error(res.data.message);
    }

    return res.data?.task;
  };

export const editItem =
  ({ id }) =>
  async dispatch => {
    navigate(ROUTES.SEARCH, { id });
  };

export const createKnowledgeLink =
  ({ categoryId, linkOwnerId, items }) =>
  async dispatch => {
    const data = items.map(item => ({
      itemId: item.id,
      ownerId: linkOwnerId,
      thumbnail: item.thumbnail ?? '',
    }));

    const res = await knowledgeServiceApi.createKnowledgeLink({ categoryId, data });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_CREATE_LINK,
      payload: {
        items: res.data,
        categoryId: categoryId,
      },
    });

    return res.data;
  };

export const deleteKnowledgeLink =
  ({ id, categoryId }) =>
  async dispatch => {
    const res = await knowledgeServiceApi.deleteKnowledgeLink({ categoryId, itemId: id });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_DELETE_LINK,
      payload: {
        id,
        categoryId,
      },
    });
  };

export const updateKnowledgeLink =
  ({ id, categoryId, linkOwnerId }) =>
  async dispatch => {
    const res = await knowledgeServiceApi.updateKnowledgeLink({
      categoryId,
      itemId: id,
      data: { ownerId: linkOwnerId },
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_UPDATE_LINK,
      payload: {
        id,
        categoryId,
        linkOwnerId,
      },
    });
  };

export const moveKnowledgeLink =
  ({ id, sourceId: categoryId, categoryId: newCategoryId, item }) =>
  async dispatch => {
    const res = await knowledgeServiceApi.moveKnowledgeLink({
      categoryId,
      newCategoryId,
      itemId: id,
      ownerId: item.linkOwnerId,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: KNOWLEDGE_MOVE_LINK,
      payload: {
        id,
        categoryId,
        newCategoryId,
      },
    });
  };
