import { GET } from '../utils/Executer';
import API from './API';
import { ORGANIZATION_SERVICE_URL as servicePath } from '../constants/APIconstants';

class OrganizationServiceAPI {
  async me() {
    return await API.client.execute(GET, `${servicePath}me`);
  }

  async available() {
    return await API.client.execute(GET, `${servicePath}available`);
  }

  async switch({ id }) {
    return await API.client.execute(GET, `${servicePath}${id}/switch`);
  }
}

export default new OrganizationServiceAPI();
