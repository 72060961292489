import update from 'immutability-helper';
import { createReducer } from '../../utils/redux/reducers.utils';
import { getParentIdByPath } from '../../utils/Utils';
import {
  AUTH_LOGOUT,
  INITIAL_STATE,
  APPLICATION_WIPE_DATA,
  ADMISSION_USE_CACHE,
  ADMISSION_UPDATE_CONTENT,
  ADMISSION_CREATE_CATEGORY,
  ADMISSION_UPDATE_CATEGORY,
  ADMISSION_DELETE_CATEGORY,
  ADMISSION_CHECK_CREATE,
  ADMISSION_CHECK_DELETE,
  ADMISSION_CHECK_UPDATE,
  ADMISSION_CATEGORY_ORDER,
  ADMISSION_CHECKLIST_LOCATION,
  ADMISSION_CHECKLIST_UPDATE_COMMENT,
  ADMISSION_CHECKLIST_UPDATE_CHECK,
  ADMISSION_CHECKLIST_RESET,
  ADMISSION_CHECK_ORDER,
} from '../actions';

const initialState = {
  categories: null,
  categoriesById: null,
  lastUpdated: null,
  previousLastUpdated: null,
  activeChecklist: {},
  isReady: false,
};

const updateData = (state, { categories, categoriesById }) =>
  update(state, {
    $merge: {
      categories,
      categoriesById,
      lastUpdated: Date.now(),
      previousLastUpdated: state.lastUpdated || Date.now(),
      isReady: true,
    },
  });

const updateCategory = (state, change) => {
  let { id } = change;
  let index = state.categories.findIndex(category => category.id === id);

  return update(state, {
    categories: {
      [index]: { $merge: change },
    },
    categoriesById: {
      [id]: { $merge: change },
    },
    lastUpdated: { $set: Date.now() },
  });
};

const deleteCategory = (state, { id }) => {
  let index = state.categories.findIndex(category => category.id === id);
  let cat = state.categoriesById[id];

  let parentId = getParentIdByPath(cat.path);

  let updateAction = {
    categories: { $splice: [[index, 1]] },
    categoriesById: {
      $apply: function (obj) {
        delete obj[id];
        return obj;
      },
    },
    lastUpdated: { $set: Date.now() },
  };

  if (parentId) {
    let indexParent = state.categories.findIndex(category => category.id === parentId);
    let currentIndex = state.categories[indexParent].subcategories.findIndex(i => i === id);
    let action = {
      subcategories: {
        $splice: [[currentIndex, 1]],
      },
    };

    updateAction.categories[indexParent] = action;
    updateAction.categoriesById[parentId] = action;
  }

  return update(state, updateAction);
};

const createCategory = (state, { path, category }) => {
  let parentId = getParentIdByPath(path);

  let updateAction = {
    categories: {
      $push: [category],
    },
    categoriesById: {
      [category.id]: { $set: category },
    },
    lastUpdated: { $set: Date.now() },
  };

  if (parentId) {
    let indexParent = state.categories.findIndex(category => category.id === parentId);
    let action = {
      subcategories: { $push: [category.id] },
    };
    updateAction.categories[indexParent] = action;
    updateAction.categoriesById[parentId] = action;
  }

  return update(state, updateAction);
};

const updateSubcategories = (state, { id, categoryId, index }) => {
  let catIndex = state.categories.findIndex(category => category.id === id);
  let currentIndex = state.categories[catIndex].subcategories.findIndex(i => i === categoryId);
  let item = state.categories[catIndex].subcategories[currentIndex];

  return update(state, {
    categories: {
      [catIndex]: {
        subcategories: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },
    categoriesById: {
      [id]: {
        subcategories: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },

    lastUpdated: { $set: Date.now() },
  });
};

const moveItem = (state, { id, categoryId, sourceId }) => {
  let destinationCatIndex = state.categories.findIndex(category => category.id === categoryId);
  let sourceCatIndex = state.categories.findIndex(category => category.id === sourceId);
  let currentIndex = state.categories[sourceCatIndex].checks.findIndex(item => item.id === id);

  let check = state.categories[sourceCatIndex].checks[currentIndex];

  return update(state, {
    categories: {
      [sourceCatIndex]: {
        checks: {
          $splice: [[currentIndex, 1]],
        },
      },
      [destinationCatIndex]: {
        checks: {
          $push: [check],
        },
      },
    },
    categoriesById: {
      [sourceId]: {
        checks: {
          $splice: [[currentIndex, 1]],
        },
      },
      [categoryId]: {
        checks: {
          $push: [check],
        },
      },
    },

    lastUpdated: { $set: Date.now() },
  });
};

const updateComment = (state, { comment, categoryId, id }) => {
  let isNew = !state.activeChecklist[categoryId];
  let isNewItem = !isNew && !state.activeChecklist[categoryId][id];
  let change = {};

  if (isNew) {
    change = {
      $set: {
        [id]: { comment },
      },
    };
  } else if (isNewItem) {
    change = {
      $merge: {
        [id]: { comment },
      },
    };
  } else {
    change = {
      [id]: {
        $merge: { comment },
      },
    };
  }

  return update(state, {
    activeChecklist: {
      [categoryId]: change,
    },
  });
};

const updateCheckState = (state, { checked, categoryId, id }) => {
  let isNew = !state.activeChecklist[categoryId];
  let isNewItem = !isNew && !state.activeChecklist[categoryId][id];
  let change = {};

  if (isNew) {
    change = {
      $set: {
        [id]: { checked },
      },
    };
  } else if (isNewItem) {
    change = {
      $merge: {
        [id]: { checked },
      },
    };
  } else {
    change = {
      [id]: {
        $merge: { checked },
      },
    };
  }

  return update(state, {
    activeChecklist: {
      [categoryId]: change,
    },
  });
};

const updateChecksOrder = (state, { id, itemId, index }) => {
  let catIndex = state.categories.findIndex(category => category.id === id);
  let currentIndex = state.categories[catIndex].checks.findIndex(i => i.id === itemId);
  let item = state.categories[catIndex].checks[currentIndex];

  return update(state, {
    categories: {
      [catIndex]: {
        checks: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },
    categoriesById: {
      [id]: {
        checks: {
          $splice: [
            [currentIndex, 1],
            [index, 0, item],
          ],
        },
      },
    },

    lastUpdated: { $set: Date.now() },
  });
};

const resetChecklist = state =>
  update(state, {
    activeChecklist: { $set: {} },
    lastUpdated: { $set: Date.now() },
  });

const useCache = state =>
  update(state, {
    isReady: { $set: true },
  });

const setInitialState = (state, { admission = initialState }) =>
  update(state, {
    $merge: {
      ...admission,
      activeChecklist: {},
      isReady: false,
    },
  });

const cleanData = (state, payload) => {
  return initialState;
};

const actionsRepo = {
  [INITIAL_STATE]: setInitialState,
  [ADMISSION_UPDATE_CONTENT]: updateData,
  [ADMISSION_UPDATE_CATEGORY]: updateCategory,
  [ADMISSION_CREATE_CATEGORY]: createCategory,
  [ADMISSION_DELETE_CATEGORY]: deleteCategory,
  [ADMISSION_CATEGORY_ORDER]: updateSubcategories,
  [ADMISSION_CHECK_CREATE]: updateCategory,
  [ADMISSION_CHECK_UPDATE]: updateCategory,
  [ADMISSION_CHECK_DELETE]: updateCategory,
  [ADMISSION_CHECK_ORDER]: updateChecksOrder,
  [ADMISSION_CHECKLIST_LOCATION]: moveItem,
  [ADMISSION_CHECKLIST_UPDATE_COMMENT]: updateComment,
  [ADMISSION_CHECKLIST_UPDATE_CHECK]: updateCheckState,
  [ADMISSION_CHECKLIST_RESET]: resetChecklist,
  [ADMISSION_USE_CACHE]: useCache,
  [APPLICATION_WIPE_DATA]: cleanData,
  [AUTH_LOGOUT]: cleanData,
};

export default createReducer({ initialState, actionsRepo });
