import API from 'shared/api/API';
import { REGISTER_API_KEY } from '../actions';

export const authenticationProcess = () => next => action => {
  if (action.type === REGISTER_API_KEY) {
    API.setApiKey(action.payload.apiKey);
  }
  next(action);
};

export const authMiddleware = [authenticationProcess];
