import { APP_IS_READY, AUTH_LOGIN } from '../actions';
import { fetchAvailableOrganizations } from '../actions/available-organizations.actions';
import userService from '../../services/user.service';

export const onAuthenticationProcessDone =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === AUTH_LOGIN || action.type === APP_IS_READY) {
      if (!userService.isAuthenticated) return false;

      dispatch(
        fetchAvailableOrganizations({
          isAuthProcess: action.type === AUTH_LOGIN,
        }),
      );
    }
  };

export const availableOrganizationsMiddleware = [onAuthenticationProcessDone];
