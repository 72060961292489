import Mixpanel from 'mixpanel-browser';
import { MIXPANEL_TOKENS } from 'shared/constants';
import { currentEnvironment } from 'shared/services/Environment';
import { ENVIRONMENT_KEYS } from 'shared/constants';

class AnalyticsService {
  async init() {
    await Mixpanel.init(MIXPANEL_TOKENS[currentEnvironment()]);
    Mixpanel.register_once({ 'client app': 'widget' });
  }

  track(eventName, properties = {}, options = {}) {
    console.debug(`[Analytics] Track event - ${eventName}`, properties);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.track(eventName, properties);
  }

  setUserId(id) {
    console.debug(`[Analytics] Set user id - ${id}`);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.identify(id);
  }

  registerUserData(data) {
    console.debug(`[Analytics] Set user data`, data);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.register(data);
    Mixpanel.register_once({ 'client app': 'widget' });
  }

  setGroup({ key, value }) {
    console.debug(`[Analytics] Set group ${key} - ${value}`);

    // Killer switch
    if (currentEnvironment() === ENVIRONMENT_KEYS.DEVELOPMENT) return false;

    Mixpanel.set_group(key, value);
  }
}

export default new AnalyticsService();
