import React, { useCallback, useState } from 'react';
import {
  ArrowIcon,
  DirectoryIcon,
  MediaNumberIcon,
  ItemNumberIcon,
  ItemBinderIcon,
} from '../../../images';
import { Tags } from '../../tags';
import sharedStyles from '../../items/items.module.scss';
import styles from './knowledge-category.module.scss';
import classnames from 'classnames';
import { CATEGORY_TYPES } from 'shared/constants';
import { KnowledgeItem } from '../../items/knowledge-item';

const KnowledgeCategoryComponent = React.memo(function KnowledgeCategoryComponent({
  id = null,
  title = '',
  tags = {},
  type = '',
  metadata = {},
  knowledgeSelectItem = () => {},
  showTags = true,
  knowledgeItems = [],
}) {
  const isCategory = type === CATEGORY_TYPES.CATEGORY;
  const [showItems, setShowItems] = useState(true);

  const onClick = useCallback(() => {
    if (isCategory) {
      knowledgeSelectItem(id);
      return;
    }
    setShowItems(state => !state);
  }, [knowledgeSelectItem, id, isCategory]);

  const renderMetadata = () => {
    if (isCategory) return;

    const hasItems = metadata?.items > 0;
    const hasMedia = metadata?.media > 0;
    const showMetadata = hasItems || hasMedia;

    if (!showMetadata) return;

    return (
      <div className={styles.metadata}>
        {hasMedia && (
          <div className={styles.metadataWrapper}>
            <img className={styles.icon} src={MediaNumberIcon} alt={'media'} />
            <h5>{metadata.media}</h5>
          </div>
        )}
        {hasItems && (
          <div className={styles.metadataWrapper}>
            <img className={styles.icon} src={ItemNumberIcon} alt={'items'} />
            <h5>{metadata.items}</h5>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        key={id}
        className={classnames([sharedStyles.item, sharedStyles.clickable])}
        onClick={onClick}>
        <div className={sharedStyles.wrapper}>
          <img
            className={sharedStyles.icon}
            src={isCategory ? DirectoryIcon : ItemBinderIcon}
            alt={isCategory ? CATEGORY_TYPES.CATEGORY : CATEGORY_TYPES.KNOWLEDGE}
          />
          <div>
            <h4>{title}</h4>
            {showTags && <Tags tags={tags} />}
          </div>
        </div>
        <div className={!showTags && sharedStyles.alignCenter}>
          {renderMetadata()}
          <img className={sharedStyles.arrow} src={ArrowIcon} alt={title} />
        </div>
      </div>

      {showItems &&
        knowledgeItems.map(item => (
          <KnowledgeItem key={item.id} showTags={showTags} itemStyle="indented" {...item} />
        ))}
    </>
  );
});

export { KnowledgeCategoryComponent };
