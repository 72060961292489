import React, { useEffect } from 'react';
import { SearchIcon } from '../../../images';
import styles from './search.module.scss';
import classnames from 'classnames';
import { Lang } from 'shared/constants';
import { EVENTS } from 'shared/constants/analytics';
import analyticsService from '../../../helpers/analytics.service';

const SearchComponent = React.memo(function SearchComponent({
  searchTerm = null,
  updateSearch = () => {},
  lang = Lang.WIDGET,
}) {
  const onSearch = React.useCallback(
    ({ target }) => {
      updateSearch(target.value);
    },
    [updateSearch],
  );

  useEffect(() => {
    if (searchTerm !== null && searchTerm !== '') {
      analyticsService.track(EVENTS.SEARCH.SEARCHING, {
        client: 'widget',
        searchTerm,
      });
    }
  }, [searchTerm]);

  return (
    <>
      <div className={classnames([styles.root, searchTerm && styles.lightBackground])}>
        <input className={styles.input} onChange={onSearch} placeholder={lang.SEARCH_PLACEHOLDER} />
        <SearchIcon className={classnames([styles.icon, searchTerm && styles.darkIcon])} />
      </div>
      {!searchTerm && <h5 className={styles.description}>{lang.SEARCH_DESCRIPTION}</h5>}
    </>
  );
});

export { SearchComponent };
