import userService from '../../services/user.service';
import {
  APPLICATION_REFRESH_DATE,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
  LAB_UPDATE_CATEGORY,
} from '../actions';
import { getLabData } from '../actions/lab.actions';

export const labFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE
    ) {
      if (!userService.isAuthenticated) return false;

      dispatch(getLabData());
    }
  };

export const labOnCategoryChangeOwnerIdMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (action.type === LAB_UPDATE_CATEGORY) {
      if (!action.payload.ownerId) return false;

      dispatch(getLabData());
    }
  };

export const labMiddleware = [labFetchDataMiddleware, labOnCategoryChangeOwnerIdMiddleware];
