import { EVENTS } from './analytics';

export const SEARCH_TYPES_KEYS = {
  none: null,
  documents: 'documents',
  media: 'media',
  compendium: 'compendium',
  admission: 'checklist',
};

export const SEARCH_TYPES = lang => [
  {
    label: lang.ALL_ITEMS,
    type: SEARCH_TYPES_KEYS.none,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_ALL,
  },
  {
    label: lang.DOCUMENTS,
    type: SEARCH_TYPES_KEYS.documents,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_DOCUMENTS,
  },
  {
    label: lang.MEDIA,
    type: SEARCH_TYPES_KEYS.media,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_MEDIA,
  },
];

export const SEARCH_PAGE_TYPES = lang => [
  {
    label: lang.ALL_ITEMS,
    type: SEARCH_TYPES_KEYS.none,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_ALL,
  },
  {
    label: lang.DOCUMENTS,
    type: SEARCH_TYPES_KEYS.documents,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_DOCUMENTS,
  },
  {
    label: lang.MEDIA,
    type: SEARCH_TYPES_KEYS.media,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_MEDIA,
  },
  {
    label: lang.COMPENDIUM,
    type: SEARCH_TYPES_KEYS.compendium,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_MEDIA,
  },
  {
    label: lang.CHECKLIST,
    type: SEARCH_TYPES_KEYS.admission,
    event: EVENTS.KNOWLEDGE_ITEMS.FILTER_BY_MEDIA,
  },
];

export const CATEGORY_TYPES = {
  CATEGORY: 'category',
  KNOWLEDGE: 'knowledge',
  VALUES: 'values',
  CHECKS: 'checklist',
  SECTION: 'section',
};

export const DASHBOARD_PARAMS = {
  page: 1,
  pageSize: 5,
  recentlyCreatedSort: 'createdAt',
  recentlyUpdatedSort: 'itemUpdatedAt',
  direction: 'desc',
};

export const KNOWLEDGE_PAGE_PARAMS = {
  pageSize: 300,
  page: 1,
};

export const SORT_BY_THUMBNAIL = ({ thumbnail: x }, { thumbnail: y }) =>
  !!x === !!y ? 0 : !!x ? 1 : -1;

export const KNOWLEDGE_TYPES = {
  CONTENT: 'content',
  LINK: 'link',
  EXTERNAL_LINK: 'external-link',
  DOCUMENT: 'document',
  PDF: 'pdf',
  IMAGE: 'image',
  VIDEO: 'video',
  OTHER: 'link',
};

export const ALLOW_IFRAME_DOMAINS = ['headtotoe.io', 'c8.health', 'c8health.com'];

export const REVISION_LOG_ADVISORS = {
  emailDomain: /@((c8\.health)|(headtotoe\.io)|(c8health\.com))/,
  name: 'C8health Advisor',
};
