import {
  APPLICATION_REFRESH_DATE,
  APPLICATION_WIPE_DATA,
  APP_IS_READY,
  AUTH_LOGIN,
  AUTH_UPDATE_TOKEN,
  FAVORITES_TOGGLE_ITEM,
  FAVORITES_UPDATE_CONTENT,
  NETWORK_CONNECTION_CHANGE_ONLINE,
} from '../actions';
import {
  addFavorite,
  deleteFavorite,
  fetchFavorites,
  saveFavoritesChangesToServer,
} from '../actions/favorites.actions';
import { getFavoriteByItemId, isItemFavorite } from '../selectors/favorites.selector';
import { updateStateToStorage } from '../actions/storage.actions';
import { getKnowledgeItemById } from '../selectors/knowledge.selector';
import userService from '../../services/user.service';

export const toggleFavoritesMiddleware =
  ({ dispatch, getState }) =>
  next =>
  async action => {
    next(action);

    if (action.type === FAVORITES_TOGGLE_ITEM) {
      const { itemId } = action.payload;
      const state = getState();
      const { favorites } = state;

      const isFavorite = isItemFavorite(favorites, { id: itemId });

      if (isFavorite) {
        const favorite = getFavoriteByItemId(favorites, { itemId });
        await dispatch(deleteFavorite({ item: favorite }));
      } else {
        const knowledgeItem = getKnowledgeItemById(state, itemId);

        if (!knowledgeItem) return false;

        await dispatch(addFavorite({ itemId: knowledgeItem.id }));
      }

      dispatch(fetchFavorites());
    }
  };

export const favoritesFetchDataMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    next(action);

    if (
      action.type === AUTH_LOGIN ||
      action.type === APP_IS_READY ||
      action.type === AUTH_UPDATE_TOKEN ||
      action.type === APPLICATION_REFRESH_DATE
    ) {
      if (!userService.isAuthenticated) return false;

      await dispatch(saveFavoritesChangesToServer());
      dispatch(fetchFavorites());
    }
  };

export const favoritesHandleDataFetchedMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    next(action);

    if (action.type === FAVORITES_UPDATE_CONTENT) {
      dispatch(updateStateToStorage());
    }
  };

export const favoritesReconnectToNetworkMiddleware =
  ({ dispatch }) =>
  next =>
  async action => {
    if (action.type === NETWORK_CONNECTION_CHANGE_ONLINE) {
      await dispatch(saveFavoritesChangesToServer());
      dispatch(fetchFavorites());
    }
    next(action);
  };

export const favoritesSaveToServerMiddleware =
  ({ dispatch }) =>
  next =>
  action => {
    if (action.type === APPLICATION_WIPE_DATA) {
      dispatch(saveFavoritesChangesToServer());
    }
    next(action);
  };

export const favoritesMiddleware = [
  toggleFavoritesMiddleware,
  favoritesFetchDataMiddleware,
  favoritesHandleDataFetchedMiddleware,
  favoritesReconnectToNetworkMiddleware,
  favoritesSaveToServerMiddleware,
];
