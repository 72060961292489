export const USER_PERMISSIONS = {
  ROOT: 'ROOT',
  ACCOUNT_ADMIN: 'ACCOUNT_ADMIN',
  COLLABORATOR: 'COLLABORATOR',
  VIEWER: 'VIEWER',
};

export const ROOT_PERMISSIONS_GROUP = [USER_PERMISSIONS.ROOT];
export const ACCOUNT_ADMIN_PERMISSIONS_GROUP = [
  USER_PERMISSIONS.ACCOUNT_ADMIN,
  ...ROOT_PERMISSIONS_GROUP,
];
export const COLLABORATOR_PERMISSIONS_GROUP = [
  USER_PERMISSIONS.COLLABORATOR,
  ...ACCOUNT_ADMIN_PERMISSIONS_GROUP,
];
export const VIEWER_PERMISSIONS_GROUP = [
  USER_PERMISSIONS.VIEWER,
  ...COLLABORATOR_PERMISSIONS_GROUP,
];

export const DEFAULT_USER_PERMISSION = USER_PERMISSIONS.VIEWER;
