import {
  ADMISSION_CHECK_CREATE,
  ADMISSION_CHECK_UPDATE,
  ADMISSION_CHECK_DELETE,
  ADMISSION_CHECKLIST_LOCATION,
  ADMISSION_USE_CACHE,
  ADMISSION_UPDATE_CONTENT,
  ADMISSION_UPDATE_CATEGORY,
  ADMISSION_CREATE_CATEGORY,
  ADMISSION_DELETE_CATEGORY,
  ADMISSION_CATEGORY_ORDER,
  ADMISSION_CHECKLIST_UPDATE_COMMENT,
  ADMISSION_CHECKLIST_UPDATE_CHECK,
  ADMISSION_CHECKLIST_RESET,
  ADMISSION_CHECK_ORDER,
} from '.';
import admissionServiceApi from '../../api/admission.service.api';
import labServiceApi from '../../api/lab.service.api';
import { isConnected } from '../selectors/network.selector';

export const getAdmissionData = () => async (dispatch, getState) => {
  let state = getState();

  if (!isConnected(state)) {
    return dispatch({
      type: ADMISSION_USE_CACHE,
    });
  }

  let { data, error } = await admissionServiceApi.getAll();

  if (error) {
    return dispatch({
      type: ADMISSION_USE_CACHE,
    });
  }

  let { categories = [] } = data || {};

  let categoriesById = {};

  categories.forEach(category => (categoriesById[category.id] = category));

  dispatch({
    type: ADMISSION_UPDATE_CONTENT,
    payload: {
      categories,
      categoriesById,
    },
  });
};

export const updateCategory =
  ({ id, title, ownerId, path, tags, keywords }) =>
  async dispatch => {
    let change = { id };

    if (title) change.title = title;
    if (ownerId) change.ownerId = ownerId;
    if (path) change.path = path;
    if (tags) change.tags = tags;
    if (keywords) change.keywords = keywords;

    let res = await admissionServiceApi.updateCategory(change);

    if (res.error) {
      // TODO: add monitor
      return false;
    }

    dispatch({
      type: ADMISSION_UPDATE_CATEGORY,
      payload: { id, title, ownerId, tags, keywords },
    });
  };

export const deleteCategory =
  ({ id }) =>
  async dispatch => {
    let res = await admissionServiceApi.deleteCategory({ id });

    if (res.error) {
      // TODO: add monitor
      return false;
    }

    dispatch({
      type: ADMISSION_DELETE_CATEGORY,
      payload: { id },
    });
  };

export const createCategory =
  ({ path, title, type, ownerId, tags, keywords }) =>
  async dispatch => {
    let { error, data } = await admissionServiceApi.createCategory({
      path,
      title,
      type,
      ownerId,
      tags,
      keywords,
    });

    if (error) {
      // TODO: add monitor
      return false;
    }

    let { category } = data;

    dispatch({
      type: ADMISSION_CREATE_CATEGORY,
      payload: {
        category,
        path,
      },
    });

    return category;
  };

export const updateCategorySubcategories =
  ({ id, categoryId, index }) =>
  async dispatch => {
    let res = await admissionServiceApi.updateCategorySubcategories({
      id,
      categoryId,
      index,
    });

    if (res.error) {
      // TODO: add monitor
      return false;
    }

    dispatch({
      type: ADMISSION_CATEGORY_ORDER,
      payload: { id, categoryId, index },
    });
  };

export const createCheck = data => async dispatch => {
  let res = await admissionServiceApi.createCheck(data);

  if (res.error) {
    throw new Error(res.data.message);
  }

  let { category } = res.data;

  dispatch({
    type: ADMISSION_CHECK_CREATE,
    payload: category,
  });
};

export const createQuickCheckListItem =
  ({ categoryId, tags }) =>
  async dispatch => {
    return dispatch(createCheck({ item: { title: '', tags }, categoryId }));
  };

export const updateCheck = data => async dispatch => {
  let res = await admissionServiceApi.updateCheck(data);

  if (res.error) {
    throw new Error(res.data.message);
  }

  let { category } = res.data;

  dispatch({
    type: ADMISSION_CHECK_UPDATE,
    payload: category,
  });
};

export const updateChecksOrder =
  ({ id, itemId, index }) =>
  async dispatch => {
    let res = await admissionServiceApi.updateChecksOrder({
      id,
      itemId,
      index,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: ADMISSION_CHECK_ORDER,
      payload: { id, itemId, index },
    });
  };

export const deleteCheck =
  ({ categoryId, id }) =>
  async dispatch => {
    let res = await admissionServiceApi.deleteCheck({ categoryId, id });

    if (res.error) {
      throw new Error(res.data.message);
    }

    let { category } = res.data;

    dispatch({
      type: ADMISSION_CHECK_DELETE,
      payload: category,
    });
  };

export const updateValueOrder =
  ({ id, itemId, index }) =>
  async dispatch => {
    let res = await labServiceApi.updateValueOrder({ id, itemId, index });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: '', // LAB_UPDATE_ITEMS_ORDER,
      payload: { id, itemId, index },
    });
  };

export const moveChecklistAction =
  ({ id, categoryId, sourceId }) =>
  async dispatch => {
    let res = await admissionServiceApi.moveChecklist({
      id,
      categoryId,
      sourceId,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    dispatch({
      type: ADMISSION_CHECKLIST_LOCATION,
      payload: { id, categoryId, sourceId },
    });
  };

export const updateChecklistComment = ({ comment, categoryId, id }) => ({
  type: ADMISSION_CHECKLIST_UPDATE_COMMENT,
  payload: { comment, categoryId, id },
});

export const updateChecklistState = ({ checked, categoryId, id }) => ({
  type: ADMISSION_CHECKLIST_UPDATE_CHECK,
  payload: { checked, categoryId, id },
});

export const resetChecklist = () => ({
  type: ADMISSION_CHECKLIST_RESET,
  payload: {},
});

export const generateChecklistReport =
  ({ sendByEmail = false, signature = {}, recipients = [] }) =>
  async (dispatch, getState) => {
    let { activeChecklist } = getState().admission;

    let res = await admissionServiceApi.generateReport({
      activeChecklist,
      sendByEmail,
      signature,
      recipients,
    });

    if (res.error) {
      throw new Error(res.data.message);
    }

    return res.data;
  };
