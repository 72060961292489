import _ from 'lodash';
import { getItemById } from './knowledge.selector';

export const isItemFavorite = (favoritesState, { id: itemId }) =>
  !_.isUndefined(favoritesState.byId[itemId]) &&
  _.isUndefined(favoritesState.byId[itemId].toDelete);

export const getFavoritesItems = ({ favorites, knowledge }) => {
  const items = [];

  if (_.isEmpty(favorites.byId) || _.isNull(knowledge.itemsById)) {
    return;
  }

  Object.keys(favorites.byId).forEach(id => {
    if (favorites.byId[id].toDelete) {
      return;
    }

    const item = getItemById(knowledge, { id });

    if (item) {
      items.push(item);
    }
  });

  return items;
};

export const getFavoriteByItemId = (favorites, { itemId }) => favorites.byId[itemId];
