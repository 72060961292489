import { SEARCH_ON_CHANGE, SEARCH_START } from '.';
import { debounce } from 'lodash';
import { isOnSearch } from '../selectors/search.selector';

const updateTerm = debounce((term, dispatch) => {
  dispatch({
    type: SEARCH_ON_CHANGE,
    payload: { term },
  });
}, 500);

export const updateSearch = term => (dispatch, getState) => {
  let isSearch = isOnSearch(getState());

  if (!isSearch) {
    dispatch({
      type: SEARCH_START,
    });
  }

  updateTerm(term, dispatch);
};

export const forceNewSearch = () => (dispatch, getState) => {
  let { term } = getState().search;

  if (!term) {
    return false;
  }

  dispatch({
    type: SEARCH_ON_CHANGE,
    payload: {
      term,
      force: true,
    },
  });
};

export const addSearchFilters = filters => (dispatch, getState) => {
  const { term } = getState().search;

  if (!term || !filters) {
    return false;
  }

  dispatch({
    type: SEARCH_ON_CHANGE,
    payload: {
      term,
      force: true,
      filters,
    },
  });
};
