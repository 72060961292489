import { GET, POST, PUT } from '../utils/Executer';
import API from './API';
import { USER_SERVICE_URL as servicePath } from '../constants/APIconstants';

class UserServiceAPI {
  async me() {
    return await API.client.execute(GET, `${servicePath}me`);
  }

  async getUsers() {
    return await API.client.execute(GET, `${servicePath}`);
  }

  async setUserPermission({ id, permissions }) {
    return await API.client.execute(PUT, `${servicePath}/${id}/permissions`, {
      permissions,
    });
  }

  async inviteUsers({ emails }) {
    return await API.client.execute(POST, `${servicePath}/invite-users`, {
      emails,
    });
  }

  async updateUser({ id, user }) {
    return await API.client.execute(PUT, `${servicePath}profile`, user);
  }

  async snoozeProfile({ id }) {
    return await API.client.execute(PUT, `${servicePath}profile/snooze`);
  }
}

export default new UserServiceAPI();
