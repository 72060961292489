import React, { useCallback } from 'react';
import styles from './widget.module.scss';
import { Search, SearchResultsComponent } from '../search';
import { Lang, ORGANIZATIONS_LOGO_URL } from 'shared/constants';
import { appURL } from 'shared/constants/APIconstants';
import { Redirect } from '../redirect';
import { KnowledgeBase } from '../knowledge-base/knowledge-base';
import { Loader } from '../loader/Loader.component';
import { KnowledgeContent } from '../items/knowledge-content/knowldege-content';

const WidgetComponent = React.memo(function WidgetComponent({
  searchTerm = null,
  results = [],
  showKnowledgeBase = false,
  showTags = true,
  lang = Lang.WIDGET,
  organization = {},
  knowledgeItemId = null,
  knowledgeShowItemContent = () => {},
}) {
  const renderLogo = () => (
    <div className={styles.logo}>
      {organization.logo && (
        <img
          src={ORGANIZATIONS_LOGO_URL + 'widget/' + organization.logo}
          alt={organization.name}
          className={styles.logo}
        />
      )}
    </div>
  );

  const closeContent = useCallback(() => {
    knowledgeShowItemContent(null);
  }, [knowledgeShowItemContent]);

  const renderPage = () => {
    if (!searchTerm && !showKnowledgeBase) {
      return <Loader />;
    }

    if (knowledgeItemId) {
      return <KnowledgeContent id={knowledgeItemId} showTags={showTags} close={closeContent} />;
    }

    if (!searchTerm) {
      return <KnowledgeBase showTags={showTags} />;
    }

    return <SearchResultsComponent items={results} searchTerm={searchTerm} lang={lang} />;
  };

  return (
    <div className={styles.root}>
      <div>
        <div className={styles.header}>
          {renderLogo()}
          <Redirect text={lang.SITE_REDIRECT} link={appURL} />
        </div>
        <Search />
        {renderPage()}
      </div>
      <div className={styles.footer}>
        <h5>{lang.COPYRIGHT}</h5>
      </div>
    </div>
  );
});

export { WidgetComponent };
