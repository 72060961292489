import React, { useCallback, useState } from 'react';
import styles from './knowledge-section.module.scss';
import { ArrowIcon } from '../../../images';
import classNames from 'classnames';
import { KnowledgeCategoryComponent } from '../knowledge-category';

const KnowledgeSectionComponent = React.memo(function KnowledgeSectionComponent({
  id = null,
  title = '',
  subcategories = [],
  knowledgeSelectItem = () => {},
  showTags = true,
}) {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded(state => !state);
  }, [setExpanded]);

  return (
    <>
      <div
        key={id}
        className={classNames([styles.section, expanded && styles.expanded])}
        onClick={toggleExpanded}>
        <div className={styles.wrapper}>
          <img
            className={classNames([styles.arrow, expanded && styles.rotate])}
            src={ArrowIcon}
            alt={title}
          />
          <div className={styles.wrapper}>
            <h5 className={styles.title}>{`${title} (${subcategories.length})`}</h5>
          </div>
        </div>
      </div>
      <div className={classNames([styles.expandedSection, !expanded && styles.hidden])}>
        {expanded &&
          subcategories.map(cat => (
            <KnowledgeCategoryComponent
              key={cat.id}
              {...cat}
              knowledgeSelectItem={knowledgeSelectItem}
              showTags={showTags}
            />
          ))}
      </div>
    </>
  );
});

export { KnowledgeSectionComponent };
