import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import analyticsService from '../../helpers/analytics.service';
import remoteConfigService from '../../helpers/remote-config.service';
import { fetchData } from '../../store/actions/application.actions';
import { registerApiKey } from '../../store/actions/auth.actions';
import { Widget } from '../widget';
import { EVENTS } from 'shared/constants';

// Set global
global.platform = 'web';

const App = React.memo(({ registerApiKey, fetchData }) => {
  const searchParams = new URLSearchParams(document.location.search);
  const apiKey = searchParams.get('wid');
  const showTags = searchParams.get('showTags') === 'true';

  useEffect(() => {
    const init = async () => {
      await remoteConfigService.init();
      await analyticsService.init();

      analyticsService.track(EVENTS.APPLICATION.OPEN);

      registerApiKey({ apiKey });
      fetchData();
    };
    init();
  });

  return <Widget showTags={showTags} />;
});

export default connect(null, { registerApiKey, fetchData })(App);
