import { getKnowledgeContent } from 'shared/store/actions/knowledge.actions';
import { getLabData } from 'shared/store/actions/lab.actions';
import { getAdmissionData } from 'shared/store/actions/admission.actions';
import { getOwners } from 'shared/store/actions/owners.actions';
import { fetchLanguageFromRemoteConfig } from './lang-local.actions';
import { updateOrganization } from 'shared/store/actions/organization.actions';

export const fetchData = () => async dispatch => {
  dispatch(fetchLanguageFromRemoteConfig());
  dispatch(getKnowledgeContent());
  dispatch(getLabData());
  dispatch(getAdmissionData());
  dispatch(getOwners());
  dispatch(updateOrganization());
};
