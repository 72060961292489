export const getOwnerById = ({ items }, { id }) => {
  return items[id] || {};
};

export const getOwnersListForEditor = ({ items }) => {
  return Object.values(items).map(({ id, firstName, lastName, email }) => ({
    id,
    value: `${firstName} ${lastName}`,
  }));
};

export const getOwnersList = ({ items }) => {
  return Object.values(items);
};
