import { DELETE, GET, POST, PUT } from '../utils/Executer';
import API from './API';
import { LAB_SERVICE_URL as servicePath } from '../constants/APIconstants';

class LabServiceAPI {
  async getCategories() {
    return await API.client.execute(GET, `${servicePath}categories`);
  }

  async getValues() {
    return await API.client.execute(GET, `${servicePath}values`);
  }

  // lab Category
  async updateCategory({ id, title, ownerId, tags, drugClass, path }) {
    return await API.client.execute(PUT, `${servicePath}categories/${id}`, {
      title,
      ownerId,
      tags,
      drugClass,
      path,
    });
  }

  async deleteCategory({ id }) {
    return await API.client.execute(DELETE, `${servicePath}categories/${id}`);
  }

  async updateCategorySubcategories({ id, categoryId, index }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}/subcategories/order`, {
      categoryId,
      index,
    });
  }

  async createCategory(data) {
    return await API.client.execute(POST, `${servicePath}categories`, data);
  }

  // Create value
  async createValue({ data }) {
    return await API.client.execute(POST, `${servicePath}values`, data);
  }

  async updateLabItem({ id, item }) {
    return await API.client.execute(PUT, `${servicePath}values/${id}`, { item });
  }

  async deleteLabItem({ id }) {
    return await API.client.execute(DELETE, `${servicePath}values/${id}`);
  }

  async updateValueOrder({ id, itemId, index }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}/values/order`, {
      itemId,
      index,
    });
  }
}

export default new LabServiceAPI();
