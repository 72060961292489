import React, { useEffect } from 'react';
import { ArrowLeftIcon, NewTabIcon, Tile } from '../../../images';
import styles from './knowledge-content.module.scss';
import { appURL } from 'shared/constants/APIconstants';
import { Redirect } from '../../redirect';
import { Lang } from 'shared/constants';
import contentStyles from '../../../styles/content-style.module.scss';
import { formatDate } from 'shared/utils/date.utils';
import { TagWithIcon } from '../../tags';
import { sanitizeHtmlContent } from 'shared/utils/Utils';

const KnowledgeContentComponent = React.memo(function KnowledgeContentComponent({
  id,
  title,
  subtitle,
  tags,
  owner,
  link,
  fileUpdatedAt,
  content = null,
  fetchKnowledgeItemContent,
  close,
  lang = Lang.WIDGET,
  langTags = Lang.TAGS,
  langTagsPlaceholder = Lang.TAGS_FORM,
  langEditor = Lang.KNOWLEDGE_EDITOR,
}) {
  const appLink = `${appURL}knowledge/items/${id}`;
  const getTagLabel = tag => langTags[tag];
  const validatedOnDate = formatDate(fileUpdatedAt);
  const ownerDetails =
    owner.firstName && owner.lastName && `${owner?.firstName} ${owner?.lastName}`;

  useEffect(() => {
    const fetchContent = async () => {
      if (!content) {
        await fetchKnowledgeItemContent({ id });
      }
    };
    fetchContent();
  }, [fetchKnowledgeItemContent, id, content]);

  const renderTags = () => {
    if (!tags?.specialty && !tags?.subspecialty) return;

    return (
      <div className={styles.tags}>
        {tags.specialty && (
          <div className={styles.tagContainer}>
            <h6>{langTagsPlaceholder.SPECIALTY_PLACEHOLDER}</h6>
            <TagWithIcon tag={tags.specialty} />
          </div>
        )}
        {tags.subspecialty && (
          <div className={styles.tagContainer}>
            <h6>{langTagsPlaceholder.SUBSPECIALTY_PLACEHOLDER}</h6>
            <TagWithIcon tag={tags.subspecialty} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.header}
        style={{
          backgroundImage: `url(${Tile})`,
        }}>
        <div className={styles.buttons}>
          <img className={styles.back} src={ArrowLeftIcon} onClick={close} alt={'Back'} />
          <div className={styles.actionButtons}>
            {link && (
              <a href={link} className={styles.openOriginal} target="_blank" rel="noreferrer">
                <span>{lang.KNOWLEDGE_OPEN_ORIGINAL}</span>
                <img
                  src={NewTabIcon}
                  className={styles.openOriginalIcon}
                  alt={lang.KNOWLEDGE_OPEN_ORIGINAL}
                />
              </a>
            )}
            <Redirect text={lang.KNOWLEDGE_REDIRECT} link={appLink} />
          </div>
        </div>
        {tags.contentType && (
          <div className={styles.contentType}>{getTagLabel(tags.contentType)}</div>
        )}
        <h1 className={styles.title}>{title}</h1>
        <h4 className={styles.subtitle}>{subtitle}</h4>
        {owner && (
          <h6 className={styles.metadata}>
            {langEditor.VALIDATED_BY}
            <a href={`mailto:${owner?.email}`} target="_blank" rel="noreferrer">
              {ownerDetails}
            </a>
            {langEditor.VALIDATED_ON.replace('{date}', validatedOnDate)}
          </h6>
        )}
        {renderTags()}
      </div>
      <div
        className={contentStyles.contentContainer}
        dangerouslySetInnerHTML={{
          __html: sanitizeHtmlContent(content),
        }}
      />
    </div>
  );
});

export { KnowledgeContentComponent };
