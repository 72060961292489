import { legacy_createStore as createStore } from '@reduxjs/toolkit';
import { reducers } from '../shared/store/reducers';
import { middleware } from '../shared/store/middlewares';
import authReducers from './reducers/auth.reducers';
import { authMiddleware } from './middleware/auth.middleware';
import knowledgeViewerReducer from './reducers/knowledgeViewer.reducer';
import { analyticsMiddleware } from './middleware/analytics.middleware';

export const store = createStore(
  reducers({
    auth: authReducers,
    knowledgeViewer: knowledgeViewerReducer,
  }),
  {},
  middleware([...authMiddleware, ...analyticsMiddleware]),
);
