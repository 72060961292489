import React from 'react';
import { Lang } from 'shared/constants';
import styles from './tags.module.scss';

const TagsComponent = React.memo(function TagsComponent({ tags = null, lang = Lang.TAGS }) {
  if (!tags) {
    return;
  }
  const getTagLabel = tag => lang[tag];

  return (
    <div className={styles.tags}>
      {tags.contentType && <h5>{getTagLabel(tags.contentType)}</h5>}
      {tags.specialty && <h5>{getTagLabel(tags.specialty)}</h5>}
      {tags.subspecialty && <h5>{getTagLabel(tags.subspecialty)}</h5>}
    </div>
  );
});
export { TagsComponent };
