import { DELETE, GET, POST, PUT } from '../utils/Executer';
import API from './API';
import { KNOWLEDGE_SERVICE_URL as servicePath } from '../constants/APIconstants';
import { DASHBOARD_PARAMS, KNOWLEDGE_PAGE_PARAMS } from '../constants/knowledge.constants';

class KnowledgeServiceAPI {
  async get() {
    return await API.client.execute(GET, `${servicePath}`);
  }

  async search({ term }) {
    return await API.client.execute(GET, `${servicePath}find?term=${term}`);
  }

  async getItemByOldId({ id }) {
    return await API.client.execute(GET, `${servicePath}old-item?id=${id}`);
  }

  async getItems() {
    return await API.client.execute(GET, `${servicePath}items`);
  }

  async getItemsByPage(params = {}) {
    const { pageSize = KNOWLEDGE_PAGE_PARAMS.pageSize, page = KNOWLEDGE_PAGE_PARAMS.page } = params;
    return await API.client.execute(
      GET,
      `${servicePath}items/page?pageSize=${pageSize}&page=${page}`,
    );
  }

  async getRecentlyCreatedItems(params = {}) {
    const {
      pageSize = DASHBOARD_PARAMS.pageSize,
      page = DASHBOARD_PARAMS.page,
      sortBy = DASHBOARD_PARAMS.recentlyCreatedSort,
      direction = DASHBOARD_PARAMS.direction,
    } = params;

    return await API.client.execute(
      GET,
      `${servicePath}items/paginated?pageSize=${pageSize}&page=${page}&sort=${sortBy}&direction=${direction}`,
    );
  }

  async getRecentlyUpdatedItems(params = {}) {
    const {
      pageSize = DASHBOARD_PARAMS.pageSize,
      page = DASHBOARD_PARAMS.page,
      sortBy = DASHBOARD_PARAMS.recentlyUpdatedSort,
      direction = DASHBOARD_PARAMS.direction,
    } = params;

    return await API.client.execute(
      GET,
      `${servicePath}items/paginated?pageSize=${pageSize}&page=${page}&sort=${sortBy}&direction=${direction}`,
    );
  }

  async getKnowledgeItemsOpenEventsForOrganization(params = {}) {
    const { pageSize = DASHBOARD_PARAMS.pageSize, page = DASHBOARD_PARAMS.page } = params;
    return await API.client.execute(
      GET,
      `${servicePath}items/analytics/organization?pageSize=${pageSize}&page=${page}`,
    );
  }
  async getKnowledgeItemsOpenEventsForUser(params = {}) {
    const { pageSize = DASHBOARD_PARAMS.pageSize, page = DASHBOARD_PARAMS.page } = params;
    return await API.client.execute(
      GET,
      `${servicePath}items/analytics/user?pageSize=${pageSize}&page=${page}`,
    );
  }

  async getItemContent({ id }) {
    return await API.client.execute(GET, `${servicePath}items/${id}/content`);
  }

  async getCategories() {
    return await API.client.execute(GET, `${servicePath}categories`);
  }

  // Knowledge Category
  async updateCategory({ id, ...change }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}`, change);
  }

  async deleteCategory({ id }) {
    return await API.client.execute(DELETE, `${servicePath}categories/${id}`);
  }

  async updateCategorySubcategories({ id, categoryId, index }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}/subcategories/order`, {
      categoryId,
      index,
    });
  }

  async updateKnowledgeItemsOrder({ id, itemId, index }) {
    return await API.client.execute(POST, `${servicePath}categories/${id}/items/order`, {
      itemId,
      index,
    });
  }

  async createCategory(data) {
    return await API.client.execute(POST, `${servicePath}categories`, data);
  }

  async moveKnowledgeItem({ id, categoryId, sourceId }) {
    return await API.client.execute(POST, `${servicePath}items/${id}/move`, {
      categoryId,
      sourceId,
    });
  }

  // Knowledge Items
  async getUploadUrl(data, options = {}) {
    return await API.assetsClient.execute(POST, `/actions/upload`, data, options);
  }

  async createKnowledgeItem({ data }) {
    return await API.client.execute(POST, `${servicePath}items`, data, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
    });
  }

  async updateKnowledgeItem({ id, data }) {
    return await API.client.execute(POST, `${servicePath}items/${id}`, data, {
      headers: {
        accept: 'application/json',
        'Accept-Language': 'en-US,en;q=0.8',
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
    });
  }

  async deleteKnowledgeItem({ id }) {
    return await API.client.execute(DELETE, `${servicePath}items/${id}`);
  }

  async createKnowledgeLink({ categoryId, data }) {
    return await API.client.execute(
      POST,
      `${servicePath}categories/${categoryId}/items/link`,
      data,
    );
  }

  async deleteKnowledgeLink({ categoryId, itemId }) {
    return await API.client.execute(
      DELETE,
      `${servicePath}categories/${categoryId}/items/link/${itemId}`,
    );
  }

  async updateKnowledgeLink({ categoryId, itemId, data }) {
    return await API.client.execute(
      PUT,
      `${servicePath}categories/${categoryId}/items/link/${itemId}`,
      data,
    );
  }

  async moveKnowledgeLink({ categoryId, newCategoryId, itemId, ownerId }) {
    return await API.client.execute(
      PUT,
      `${servicePath}categories/${categoryId}/items/link/${itemId}/move`,
      { newCategoryId, ownerId },
    );
  }

  // OCR conversion
  async triggerOcrConversion({ assetId }) {
    return await API.client.execute(POST, `${servicePath}assets/${assetId}`);
  }

  async fetchOcrData({ assetId }) {
    return await API.client.execute(GET, `${servicePath}assets/${assetId}`);
  }
}

export default new KnowledgeServiceAPI();
