import _ from 'lodash';
import { filterByExpiryDate } from '../../hooks/knowledge.hooks';
import { normalizedContains, removeDiacritics } from '../../utils/Utils';
import { filterByTags } from 'shared/utils/search.utils';

// Search for knowledge item
const searchOnKnowledgeItem = ({ title, subtitle, keywords = [] }, term) => {
  return (
    normalizedContains(title, term) ||
    normalizedContains(subtitle, term) ||
    !!keywords.find(key => normalizedContains(key, term))
  );
};

// Search for lab item
const searchOnLabItem = ({ title, value, keywords = [] }, term) => {
  return (
    normalizedContains(title, term) ||
    normalizedContains(value, term) ||
    !!keywords.find(key => normalizedContains(key, term))
  );
};

// Search for admission item
const searchOnAdmissionCategories = ({ title, value, keywords = [] }, term) => {
  return normalizedContains(title, term) || !!keywords.find(key => normalizedContains(key, term));
};

export const innerSearch = (results = [], term) => {
  let termSplit = term.split(' ');

  for (let i = 1; i < termSplit.length; i++) {
    if (results.length === 0) return results;
    results.lab = results.lab.filter(item => searchOnLabItem(item, termSplit[i]));
    results.knowledge = results.knowledge.filter(item => searchOnKnowledgeItem(item, termSplit[i]));
    results.admission = results.admission.filter(item =>
      searchOnAdmissionCategories(item, termSplit[i]),
    );
  }

  return results;
};

const addToMap = (map, tags = {}) => {
  Object.keys(tags).forEach(key => {
    if (!tags[key]) return;

    if (_.isArray(tags[key])) {
      tags[key].forEach(arrKey => {
        if (!map[key][arrKey]) {
          map[key][arrKey] = 0;
        }
        map[key][arrKey]++;
      });
      return;
    }

    if (!map[key][tags[key]]) {
      map[key][tags[key]] = 0;
    }
    map[key][tags[key]]++;
  });
};

export const search = (state, term, filters) => {
  let knowledge = state.knowledge.items;
  let lab = state.lab.items;
  let admission = state.admission.categories;
  let results = {};
  let termSplit = removeDiacritics(term).split(' ');
  let tagsMap = {
    specialty: {},
    subspecialty: {},
    contentType: {},
    targetAudience: {},
  };

  const filtersByTags = filterByTags({ filters });

  // Search for knowledge items
  results.knowledge = knowledge.filter(knowledgeItem =>
    searchOnKnowledgeItem(knowledgeItem, termSplit[0]),
  );

  // Search for lab items
  results.lab = lab.filter(labItem => searchOnLabItem(labItem, termSplit[0]));

  // Search for admission items
  results.admission = admission.filter(admissionCategories =>
    searchOnAdmissionCategories(admissionCategories, termSplit[0]),
  );

  let initialResults = _.clone(results);

  results = innerSearch(results, term);

  // Map tags
  results.knowledge
    .filter(filterByExpiryDate)
    .filter(filtersByTags)
    .forEach(item => addToMap(tagsMap, item?.tags));

  results.lab.filter(filtersByTags).forEach(item => addToMap(tagsMap, item?.tags));

  results.admission.filter(filtersByTags).forEach(item => addToMap(tagsMap, item?.tags));

  results.tagsMap = tagsMap;

  return { initialResults, results };
};

export const isOnSearch = ({ search }) => {
  return search.isOnSearch;
};
